var Search = function(options) {
    var self = this;
    self.options = $.extend({
        template: '',
        empty: ''
    }, options || {});


    var showHideField = function(item) {
        if ($('#search').hasClass('hidden') || !$('#search').is(":visible")) {
            if (!$('#search').hasClass('hidden') && !$('#search').is(":visible")) {
                $('#search').css('display', 'block');
            }
            item.addClass('active');
            $("#search-overlay").show();
            $('#search').removeClass('hidden');
            $('#search-query').focus();
        } else {
            $('#search').addClass('hidden');
            item.removeClass('active');
            $("#search-overlay").hide();
        }
    };

    return {
        init: function(options) {
            self.options = $.extend(self.options, options || {});
    //
    //         var suggestions = new Bloodhound({
    //             datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
    //             queryTokenizer: Bloodhound.tokenizers.whitespace,
    //             remote: {
    //                 url: _searchAutocompleteRoute + '?q=%QUERY',
    //                 wildcard: '%QUERY'
    //             }
    //         });
    //
    //         // make search work  by clicking enter
    //         // $("#search-query, #mobile-search-query").keydown(function(event){
    //         //     if(event.keyCode == 13){
    //         //         if ($('.tt-cursor').length == 0) {
    //         //             $('.loader').removeClass('hidden');
    //         //             // if no suggestion is selected search by what is written
    //         //             window.location = _searchRoute + "?search=" + $(this).val();
    //         //         }
    //         //     }
    //         // });
    //
    //         $('#search-query, #mobile-search-query').typeahead(null, {
    //             source: suggestions,
    //             displayKey: 'title',
    //             templates: {
    //                 suggestion: self.options.template,
    //                 empty: self.options.empty
    //             },
    //             limit: Infinity
    //         }).on('typeahead:asyncrequest', function () {
    //             $('.act-hideon-load').addClass('hidden');
    //             $('.act-loading-search').show();
    //         }).on('typeahead:asynccancel typeahead:asyncreceive', function () {
    //             $('.act-hideon-load').removeClass('hidden');
    //             $('.act-loading-search').hide();
    //         }).on('typeahead:open', function () {
    //             if ($(window).width() < RESPONSIVE_BREAKPOINTS.tablet_l) {
    //                 $('body').addClass('fixed');
    //             }
    //         }).on('typeahead:close', function() {
    //             $('body').removeClass('fixed');
    //         });
    //
    //         $('#search-query, #mobile-search-query').bind('typeahead:select', function (ev, suggestion) {
    //             $('.loader').removeClass('hidden');
    //             window.location = suggestion.url;
    //         });
    //
    //         $('#search-query').focus(function() {
    //             if ($(window).width() >= RESPONSIVE_BREAKPOINTS.tablet_l) {
    //                 // search and clear  buttons inside field
    //                 $(this).parent().parent().find('.search-action-link').each(function() {
    //                     $(this).hide();
    //                 });
    //
    //                 $('.act-clear-search').show();
    //             } else {
    //                 // clear button outside field
    //                 // console.log($(this).parent().parent());
    //             }
    //         });
    //
    //         $('#search-query').focusout(function() {
    //             if ($(window).width() >= RESPONSIVE_BREAKPOINTS.tablet_l && $(this).val().length == 0) {
    //                 $(this).parent().parent().find('.search-action-link').each(function() {
    //                     $(this).hide();
    //                 });
    //
    //                 $('.act-submit-search').show();
    //             } else {
    //
    //             }
    //         });
    //
    //
            $('[data-for="#search"]').click(function() {
                showHideField($(this));
            });
    //
    //         $("#search-overlay").on('click', function() {
    //             $(this).hide();
    //             $('#search').addClass('hidden');
    //             $('.btn.search').removeClass('active');
    //         });
    //
    //         $(".act-clear-search").on('click', function() {
    //             $("#search-query").val('');
    //             $(".search-action-link").hide();
    //             $(".act-submit-search").show();
    //         });
    //
    //         $(".act-submit-search").on('click', function() {
    //             $(this).closest("form").submit();
    //         });
    //
            $(".act-close-search").on('click', function() {
                $("#search-overlay").hide();
                $('#search').addClass('hidden');
                $('.btn.search').removeClass('active');
            });
        }
    }
}();