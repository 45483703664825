$(document).ready(function() {
    var bottom = 0 - ($('#feedback').height() - $('#feedback-slide').height());
    $('#feedback').css({
        'bottom' : bottom + 'px'
    });
    if(Cookies.get('feedback') == 'disabled')
        $('#feedback').hide();

    $('#feedback-slide #feedback-disable').click(function(){
        Cookies.set('feedback', 'disabled');
        $('#feedback').hide();
    });
    $('#feedback-slide').click(function(){
        var bottom = 0 - ($('#feedback').height() - $('#feedback-slide').height());
        $('#feedback').toggleClass("open");
        if(! $('#feedback').hasClass("open")) {
            $('#feedback').css({
                'bottom' : bottom + 'px'
            });
        } else {
            $('#feedback').css({
                'bottom' : '0px'
            });
        }
    });
    $("#frmContact").on('submit',(function(e){
        e.preventDefault();
        var valid;
        valid = validateContact();
        if(valid) {
            $('.btn-send .loader').removeClass('hidden');
            $('.btn-send input').css({
                'color': '#000'
            }).prop('disabled', true);

            $.ajax({
                url: _localeSlug + "/feedback",
                type: "POST",
                data:  new FormData(this),
                contentType: false,
                cache: false,
                processData:false,
                success: function(data){
                    if (! data.success) {
                        $(".feedback-errors").html(data.errors);
                    } else {
                        $(".feedback-top").css('display', 'none');
                        $("#frmContact").css('display', 'none');
                        $(".feedback-content").css({"min-height": "362px", "display": "table"});
                        $(".feedback-form").css({"display": "table-cell", "vertical-align": "middle"});
                        $("#mail-status").html(data.message);
                    }

                    $('.btn-send .loader').addClass('hidden');
                    $('.btn-send input').css({
                        'color': '#b3b3ad'
                    }).prop('disabled', false);
                },
                error: function(){}
            });
        }
    }));

    function validateContact() {
        var valid = true;
        $(".feeback-check").removeClass("feedback-error");
        $("label").removeClass("upload-error");
        $(".feedback-errors").html('');

        if(! $("#file-3").val()) {
            $(".feedback-errors").append(feedback_translations.file_required + "<br />");
            $("label").addClass("upload-error");
            valid = false;
        }

        // if(! $("#userName").val() || ! $("#userEmail").val() || ! $("#userEmail").val()) {
        //     $(".feedback-errors").append("Ne visi laukai užpildyti, prašome patikrinti.<br />");
        // }

        if(! $("#userName").val()) {
            $(".feedback-errors").append(feedback_translations.name_required + "<br />");
            $("#userName").addClass("feedback-error");
            valid = false;
        }
        if (! $("#userEmail").val()) {
            $(".feedback-errors").append(feedback_translations.email_required + "<br />");
            $("#userEmail").addClass("feedback-error");
            valid = false
        }
        if (! $("#userMessage").val()) {
            $(".feedback-errors").append(feedback_translations.message_required + "<br />");
            $("#userMessage").addClass("feedback-error");
            valid = false
        }

        if(! $("#userEmail").val().match(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/)) {
            $(".feedback-errors").append(feedback_translations.email_error + "<br />");
            $("#userEmail").addClass("feedback-error");
            valid = false;
        }

        return valid;
    }

    'use strict';
    ( function ( document, window, index )
    {
        var inputs = document.querySelectorAll( '.inputfile' );
        Array.prototype.forEach.call( inputs, function( input )
        {
            var label	 = input.nextElementSibling,
            labelVal = label.innerHTML;
            input.addEventListener( 'change', function( e )
            {
                var fileName = '';
                if( this.files && this.files.length > 1 )
                    fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
                else
                    fileName = e.target.value.split( '\\' ).pop();
                if( fileName ) {
                    label.querySelector('span').innerHTML = fileName;
                    label.querySelector('span').style.textDecoration="none"
                }
                else
                {
                    label.innerHTML = labelVal;
                }
            });
            // Firefox bug fix
            input.addEventListener( 'focus', function(){ input.classList.add( 'has-focus' ); });
            input.addEventListener( 'blur', function(){ input.classList.remove( 'has-focus' ); });
        });
    }( document, window, 0 ));
});