/**
 * Animation for the hamburger
 */


/**
 * Cart component
 */
(function (window)
{
    var mobile_nav = (function ()
    {
        function mobile_nav()
        {
            this.menu = $("#menu-mobile");
            this.menu_offcanvas = $("#menu-mobile-offcanvas");
            this.toggle = $(".navbar-toggle");
            this.navbar_toggle();
            this.listener();
            //this.search();
        }

        mobile_nav.prototype = {

            navbar_toggle: function ()
            {
                var self = this;
                $(self.toggle).click(function () {
                    $(this).toggleClass("active");
                    $(self.menu).toggleClass("active");
                    $(self.menu_offcanvas).toggleClass("active");
                    $("body").toggleClass("menu-mobile-open");
                    $('.menu-mobile-offcanvas').hide();
                    self.menu_offcanvas.show();
                    return false;
                });

                return true;
            },
            listener: function ()
            {
                var self = this;
                $("#menu-mobile-offcanvas .menu-change").on('click', function () {
                    var parent_id = $(this).data('parent-id');
                    var id = $(this).attr('href');

                    if (typeof parent_id != 'undefined') {
                        $("#menu-mobile-offcanvas .menu-back").data('back-id', parent_id);
                    }

                    self.changeMenu(id);
                    return false;
                });

                $("#menu-mobile-offcanvas .menu-back").on('click', function () {
                    var back_id = $(this).data('back-id');
                    if (typeof back_id != 'undefined' && back_id != '') {
                        $(this).data('back-id', '');
                        self.changeMenu('#mobile-' + back_id);
                    } else {
                        self.defaultMenu();
                    }
                    return false;
                });

                return true;
            },
            changeMenu: function (element)
            {
                var self = this;
                var menu = $(self.menu_offcanvas);
                menu.find('.menu-sub > div').css('display', '');
                menu.find(element).show();
                menu.find('.menu').addClass('hidden');
                menu.find('.menu-default').hide();
                menu.find('.menu-back').show();
                return true;
            },
            defaultMenu: function ()
            {
                var self = this;
                var menu = $(self.menu_offcanvas);
                menu.find('.menu-sub > div').css('display', '');
                menu.find('.menu').removeClass('hidden');
                menu.find('.menu-default').show();
                menu.find('.menu-back').hide();
            }

            // search: function () {
            //     $("#menu-mobile .open-search").click(function(){
            //         if (!$("#menu-mobile-search").is(":visible")) {
            //             $("#menu-mobile-search").show();
            //             $("#menu-shadow").show();
            //             $('#mobile-search-query').focus();
            //         } else {
            //             $("#menu-mobile-search").hide();
            //             $("#menu-shadow").hide();
            //         }
            //
            //         return false;
            //     });
            // }
        };

        return mobile_nav;
    }());

    mobile_nav.create = function (element, actual_cart)
    {
        return new mobile_nav(element, actual_cart);
    };

    window.mobile_nav = mobile_nav;
}(window));
mobile_nav = mobile_nav.create();