$(document).ready(function() {
	$(document).on('click', '.facebookLoginButton', fbRegister);
});

function fbRegister() {
	var current_url = location.href;
	FB.init({
		appId      : facebookAppId,
		status     : true, // check login status
		cookie     : true, // enable cookies to allow the server to access the session
		oauth      : true, // enable OAuth 2.0
		xfbml      : true , // parse XFBML
		version    : 'v2.10'
	});

	FB.login(function(response) {
		if (response.authResponse) {
			jQuery.ajax({
				url : facebookLoginUrl,
				type : 'GET',
				dataType : 'json',
				data : {
					access_token: response.authResponse.accessToken,
					request_url: current_url
				},
				success : function(response){
					if(response && response.success) {

						google_ga4.login();

						window.location = response.redirect ? response.redirect : '/';
					}
				},
				error: function(err) {
					console.log(err);
				}
			});
		} else {
			console.log('User cancelled login or did not fully authorize.');
		}
	}, {scope: 'email'});
}