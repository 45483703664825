// Globals
var RESPONSIVE_BREAKPOINTS = {
    'desktop': Infinity,
    'tablet_l': 977, //on chrome it is 977, need to find why. Need to be 992
    'tablet_p': 768,
    'mobile_l': 480,
    'mobile_p': 320
};

function cookieRequired()
{
  $(this).css('border-left', '10px solid black');
  $('.func').css('border-left', '10px solid lightgrey');
  $('.third').css('border-left', '10px solid lightgrey');
  $('.functional').attr('value', '0');
  $('.thirdparty').attr('value', '0');
}

function cookieFunctional()
{
  $(this).css('border-left', '10px solid black');
  $('.req').css('border-left', '10px solid black');
  $('.third').css('border-left', '10px solid lightgrey');
  $('.functional').attr('value', '1');
  $('.thirdparty').attr('value', '0');
}

function cookieThirdParties()
{
  $(this).css('border-left', '10px solid black');
  $('.req').css('border-left', '10px solid black');
  $('.func').css('border-left', '10px solid black');
  $('.functional').attr('value', '1');
  $('.thirdparty').attr('value', '1');
}

/**
 * Header specific JS
 */
$(window).ready(function () {
// fix for slick slider -> products
    $('.slick-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $(window).trigger('scroll');

        $(slick.$slides).each(function(index,item){
            $(item).show();
        });

        $(slick.$slides).each(function(){
            if ($(this).hasClass('slick-active')) {
                $(this).children().lazyLoadXT({show: true});
            }
        });
    });
// fix for slick slider -> products

    mobile = 0;

    var resize = function resize() {
        var width = $(window).width();
        if (width <= 990) {
            mobile = 1;
        } else {
            mobile = 0;
        }
        hidden_mobile();
    };

    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': _token
        }
    });

    window.refreshSession = function () {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", "/refresh", true);
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-Token': xhr.responseText
                        }
                    });
                }
            }
        };
        xhr.onerror = function (e) {
            window.location.reload();
        };
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.send(null);
    };

    /**
     * Refresh session
     * in 30 minutes
     */
    setInterval(function () {
        window.refreshSession();
    }, 1800000);

    /**
     * Show/hide menu assets on hover/blur
     *
     * @param action
     */
    $(window).resize(resize).resize();

    function hidden_mobile() {
        var el = $('.hidden-mobile');
        if (mobile == 1) {
            if (el.length > 0) {
                el.hide();
            } else {
                el.show();
            }
        } else {
            el.show();
        }
    };

    $('select').select2({
        minimumResultsForSearch: 1,
        "language": {
            "noResults": function() {
                return "";
            }
        },
        escapeMarkup: function (markup) {
            return markup;
        }
    });

    $('select.search').select2();

    $('select.white').select2({
        theme: "white",
        minimumResultsForSearch: -1
    });

    $('select.white.search').select2({
        theme: "white"
    });

    $('.form-edit input').on('change keyup', function () {
        $(this).closest('form').find('.btn-darkgrey').removeClass('btn-darkgrey').addClass('btn-black');
    });


    // BEGIN: Carousel
    $('.carousel').carousel();

    $('.carousel.slide').on('slide.bs.carousel', function (e) {
        var $nextImage = null;
        $activeItem = $('.active.item', this);
        $nextImage = $activeItem.next('.item').find('img');
        $($nextImage).lazyLoadXT();
    });
    // END: Carousel

    $('.box.header > .mobile select').on('change', function () {
        window.location = $(this).val();
    });

    $('.unlock_return').change(function () {
        var selects = $(this).closest('.return-product-list').find('select');
        var disabled = selects.eq(0).prop('disabled');
        selects.prop('disabled', !disabled);
    });

    $(".menu-left li.dropdown-for").hover(function () {
            $('.menu-left li.dropdown-for').not(this).addClass('grey');
        },
        function () {
            $('.menu-left li.dropdown-for').not(this).removeClass('grey');
        });


    // START IOS Double tap issue fix/hack
    function is_touch_device() {
        return 'ontouchstart' in window        // works on most browsers
            || navigator.maxTouchPoints;       // works on IE10/11 and Surface
    };

    if (is_touch_device()) {
        $('body').addClass('touch');
    } else {
        $('body').addClass('no-touch');
    }
    // END IOS Double tap issue fix/hack

    // $('.menu-left li.dropdown-for').not(":hover").addClass('grey');
    // $('.menu-left li.dropdown-for:hover').removeClass('grey');

    /**
     * Validates newsletter email
     */
    $('#newsletter').on('submit', function (e) {
        var email = $(this).find('input[name="email"]').val();

        if (! isEmailValid(email)) {
            e.preventDefault();
            $('#newsletter .form-group').addClass('has-error');
            $('#newsletter .help-block').removeClass('hidden');
            return false;
        }

        google_ga4.newsletter_subscription();

    });

  // $.ajax({
  //   type: "get",
  //   url: '/cookie_get',
  //   dataType: 'json',
  //   success: function (response) {
  //     if (jQuery.inArray('law', response)) {
  //       cookieRequired.call(this);
  //       if (jQuery.inArray('functional', response)) {
  //         cookieFunctional.call(this);
  //
  //         if (jQuery.inArray('thirdparty', response)) {
  //           cookieThirdParties.call(this);
  //
  //         }
  //       }
  //     }
  //   },
  //   error: function (e) {
  //     console.log('error getting cookies ', e);
  //   }
  // });

  $('.flexed-column .row.relative').on('click', function () {
        $('.range-dot').addClass('hidden');
        $(this).find('.range-dot').toggleClass('hidden');

        if ($(this).hasClass('req')) {
            cookieRequired.call(this);
        }

        if ($(this).hasClass('func')) {
            cookieFunctional.call(this);
        }

        if ($(this).hasClass('third')) {
            cookieThirdParties.call(this);
        }
    });
    //
    // $('#cookieModal').on('shown.bs.modal', function () {
    //   var maxHeight = 0;
    //
    //   $(".flexed-column .row").each(function(){
    //   if ($(this).height() > maxHeight) {
    //       maxHeight = $(this).height();
    //   }
    //   });
    //
    //   $(".flexed-column .row").height(maxHeight + 20);
    // });

    $('a#linkCookieModal').on('click', function () {
        $('#cookieModal').modal('show');
    });

    $('#modal-cookie-btn').on('click', function () {
      $('#cookieModal').modal('hide');
      var thirdparty = $('.thirdparty').attr('value');
      var functional = $('.functional').attr('value');

      $.ajax({
        type: "post",
        url: _localeSlug + '/cookie_agree',
        data: {
          cookie_law: true,
          functional: functional,
          thirdparty: thirdparty
        },
        dataType: 'json'
      });
    });

    $('#newsletter-subscribe').on('keydown',  function () {
        $(this).closest('.form-group').removeClass('has-error');
        $(this).closest('.form-group').find('.help-block').addClass('hidden');
    });

      $(document).on("click", ".newsletter button", function (e) {
        e.preventDefault();
        $(this).siblings("button").removeClass('clicked');
        $(this).addClass('clicked');
        var value = $(this).attr('value');

        $('.newsletter_type').val(value);
        $('#newsletter_acc').val(value);
        $('#notifications-email-0').val(value);

        $(this).closest('form').find('.btn-darkgrey').removeClass('btn-darkgrey').addClass('btn-black');

        if (value === '1') {
            $('.clicked-value').val(value);
            $('#notifications-email-0').attr('checked', true);

            google_ga4.newsletter_subscription();
        } else {
          $('.clicked-value').val(1);
          $('#notifications-email-0').attr('checked', false);
        }

      });

      $(document).on("click", "#terms_and_conditions", function () {
        if ($(this).is(':checked')) {
            $(this).val(1);
            $('.terms_services_option').val(1);
            $('#terms_services_acc').val(1);
        } else {
            $(this).val(0);
            $('.terms_services_option').val(0);
            $('#terms_services_acc').val(0);
        }
      });

      // On load open newsletter modal after facebook redirect
        $(window).on('load',function(){
            $('#newsletter-modal').modal('show');
        });

        
    var isEmailValid = function(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    //Fixing footer mobile version collapse problem.
    var $collapseGroup = $('#collapseGroup');
    $collapseGroup.on('show.bs.collapse','.collapse', function() {
        $collapseGroup.find('.collapse.in').collapse('hide');
    });

    if ($('#navUp').length) {
        $(document).on('click', '#navUp', function(){
            $("html, body").animate({ scrollTop: 0 }, "slow");
        });
        function check_scrollup() {
            var width = $(window).width();
            $('#navUp').stop();
            if ($(window).scrollTop() > 200) {
                $('#navUp').fadeIn();

                if (width <= 991) {
                    $('#feedback').hide();
                } else {
                    $('#feedback').show();
                }
            } else {
                $('#navUp').fadeOut();

                if (width <= 991) {
                    $('#feedback').show();
                }
            }
        }
        check_scrollup();
        $(window).scroll(check_scrollup);
    }

    $(document).on('click', '.act-add2wish', function(e) {
        e.preventDefault();

        let element = $(this);
        let product_id = $(this).data('id');
        let data = {
            "product_id": product_id,
        };

        element.find('.fa-heart').hide();
        element.find('.fa-spinner').show();

        if (element.hasClass('added')) {
            $.ajax({
                url: _localeSlug + '/wishlist/'+ product_id +'/delete',
                method: "delete",
                data: data,
                success: function (response) {
                    if (response.success) {
                        element.toggleClass('added');
                        element.find('.fa-spinner').hide();
                        element.find('.fa-heart').show();
                        $('#wishlist-top').html(response.wishlist_header_html);
                        if ($(document).find('#wishlist-items').length > 0) {
                            element.parents('.product-block').remove();
                        }
                    }
                }
            });
        } else {
            $.ajax({
                url: _localeSlug + '/wishlist/update_wishlist',
                method: "post",
                data: data,
                success: function (response) {
                    if (response.success) {
                        element.toggleClass('added');
                        element.find('.fa-spinner').hide();
                        element.find('.fa-heart').show();
                        $('#wishlist-top').html(response.wishlist_header_html);

                        google_ga4.add_to_wishlist({value:'', 'products': response.products});
                    }
                }
            });
        }

    });

    $(document).on('submit', '.registerFormWrap form, .loginFormWrap form', function() {
       $(this).find('button.btn-success span.active-hidden').hide();
       $(this).find('button.btn-success i.fa-spinner').removeClass('hidden');
    });

    $(document).on('click', ".act-mob-filter-button", function() {
        $("#menu-mobile-offcanvas-filter > button[data-target='#menu-mobile-offcanvas']").trigger("click");
    });
});
