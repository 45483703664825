/**
 * Cart component
 */
(function (window) {
    var google_e_commerce = {
        /**
         * Create required parameters
         * @param googleOrder
         * @param googleProducts
         */
        create: function (googleOrder, googleProducts) {
            ga('require', 'ecommerce');
            this.addTransaction(googleOrder);
            this.addProducts(googleProducts);
            ga('ecommerce:send');
            ga('ecommerce:clear');
        },

        /**
         * Gather all products and send it to google
         */
        addProducts: function (googleProducts) {
            googleProducts.forEach(function (product, key) {
                ga('ecommerce:addProduct', product);
            });
        },

        /**
         * Gather all products and send it to google
         */
        addTransaction: function (googleOrder) {
            ga('ecommerce:addTransaction', googleOrder);
        }
    };

    window.google_e_commerce = google_e_commerce;
}(window));
