/**
 * Cart component
 */
(function (window) {
    var google_ga4 = {

        user_info: typeof(window.REGISTERED_USER) != 'undefined' ? window.REGISTERED_USER : {},

        view_item_list: function (data) {
            window.dataLayer.push({
                event: "view_item_list",
                ecommerce: {
                    items:data.products,
                    user_data:this.user_info
                }
            });
        },

        select_item: function (data) {
            window.dataLayer.push({
                event: "select_item",
                ecommerce: {
                    items:data.products,
                    user_data:this.user_info
                }
            });
        },

        view_item: function (data) {
            window.dataLayer.push({
                event: "view_item",
                ecommerce: {
                    items:data.products,
                    user_data:this.user_info
                }
            });
        },

        add_to_wishlist : function (data) {
            if(data.value == '' || data.value == 0) {
                data.value = 0;
                $(data.products).each(function(index,p){
                    data.value += parseFloat(p.price);
                });
            }

            window.dataLayer.push({
                event: "add_to_wishlist",
                ecommerce: {
                    currency: "EUR",
                    value: data.value.toFixed(2),
                    items: data.products,
                    user_data:this.user_info
                }
            });
        },

        add_to_card : function (data) {
            window.dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                    items: data.products,
                    user_data:this.user_info
                }
            });
        },

        remove_from_cart : function (products) {
            window.dataLayer.push({
                event: "remove_from_cart",
                ecommerce: {
                    items: products,
                    user_data:this.user_info,
                }
            });
        },

        view_cart : function (data) {
            window.dataLayer.push({
                event: "view_cart",
                ecommerce: {
                    currency: "EUR",
                    value: data.value,
                    items: data.products,
                    user_data:this.user_info
                }
            });
        },

        begin_checkout : function (data) {
            window.dataLayer.push({
                event: "begin_checkout",
                ecommerce: {
                    items: data.products,
                    user_data:this.user_info
                }
            });
        },

        add_shipping_info : function (data) {
            window.dataLayer.push({
                event: "add_shipping_info",
                ecommerce: {
                    currency: "EUR",
                    value: data.value,
                    shipping_tier: data.shipping_tier,
                    items: data.products,
                    user_data:this.user_info
                }
            });
        },

        add_payment_info : function (data) {
            window.dataLayer.push({
                event: "add_payment_info",
                ecommerce: {
                    currency: "EUR",
                    value: data.value,
                    payment_type: data.payment_type,
                    items: data.products,
                    user_data:this.user_info
                }
            });
        },

        purchase : function (data) {
            window.dataLayer.push({
                event: "purchase",
                ecommerce: {
                    transaction_id: data.transaction_id,
                    affiliation: data.affiliation,
                    value: data.value,
                    tax: data.tax,
                    shipping: data.shipping,
                    currency: "EUR",
                    coupon: data.coupon,
                    items: data.products,
                    user_data:this.user_info
                }
            });
        },

        refund : function (data) {
            window.dataLayer.push({
                event: "refund",
                ecommerce: {
                    transaction_id: data.transaction_id,
                    affiliation: data.affiliation,
                    value: data.value,
                    tax: data.tax,
                    shipping: data.shipping,
                    currency: "EUR",
                    coupon: data.coupon,
                    items: data.products,
                    user_data:this.user_info
                }
            });
        },

        sign_up : function (data) {
            window.dataLayer.push({
                event: "sign_up"
            });
        },

        login : function (data) {
            window.dataLayer.push({
                event: "login"
            });
        },

        newsletter_subscription : function (data) {
            window.dataLayer.push({
                event: "newsletter_subscription"
            });
        },
    };

    window.google_ga4 = google_ga4;
}(window));
