/**
 * Product list block
 */
$(document).ready(function () {
    var element = $('.product-list-block');
    
    if (element.length) {
        element.slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1230,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '50px 0 5px'
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        arrows: false,
                        centerMode: true,
                        centerPadding: '50px 0 5px'
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                        centerMode: true,
                        centerPadding: '30px 0 5px'
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        arrows: false,
                        centerMode: true,
                        centerPadding: '20px 0 5px',
                        lazyLoad: 'progressive'
                    }
                }
            ]
        });
    }
});