var Account = function() {
    return {
        init: function(){
            $('.address-list li .delete-btn').on('click', function(e){
                e.preventDefault();
                var div = $(this).closest('.address-list li');
                var form = $(this).closest('form');

                var url = form.attr('action');
                var data = form.serialize();

                $.ajax({
                    url: url,
                    method: "delete",
                    data: data,
                    success: function(response) {
                        if (response.success) {
                            div.remove();
                        } else {
                            NotificationPopups.toastr().error('Address can\'t be deleted');
                        }
                    }
                });

                return false;
            });
        }
    }
}();

