/**
 * Ecommerce component
 */

(function (window) {    
    var enhancedEcomm = {
        /**
         * PushData required parameters
         * @param type
         * @param Product
         * @param Order Sum
         */
        pushData: function (type, products, order_sum) {
            window.dataLayer = window.dataLayer || [];
            if (type != 'ProductView' && type != 'addToCart' && type != 'removeFromCart' && type != 'checkout' && type != 'purchase') {
                return false;
            }

            if (type != 'Purchase') {
                var measurment = "";
                if (type == 'ProductView') {
                    measurment = 'detail';
                } else if (type == 'addToCart') {
                    measurment = 'add';
                } else if (type == 'removeFromCart') {
                    measurment = 'remove';
                } else if (type == 'checkout') {
                    measurment = 'checkout';
                }
                
                ecomm = {
                    currencyCode: 'EUR',
                };
                ecomm[measurment] =  {
                    products: products
                };
                
            } else {
                tmp = ""
                $.each(products, function(index, item){
                    tmp += item.id+",";
                });
                items_ids = tmp.substring(0,tmp.length - 1);

                ecomm = {
                    currencyCode: 'EUR',
                    revenue: order_sum,
                    id: items_ids,
                    add: {
                        products: products
                    }
                };
            }
            
            window.dataLayer.push({
                event: type,
                ecommerce: ecomm
            });
        }
    };
    window.enhancedEcomm = enhancedEcomm;
}(window));

/*(function (window) {
    var google_e_commerce = {
        /!**
         * Create required parameters
         * @param googleOrder
         * @param googleProducts
         *!/
        create: function (googleOrder, googleProducts) {
            ga('require', 'ecommerce');
            this.addTransaction(googleOrder);
            this.addProducts(googleProducts);
            ga('ecommerce:send');
            ga('ecommerce:clear');
        },

        /!**
         * Gather all products and send it to google
         *!/
        addProducts: function (googleProducts) {
            googleProducts.forEach(function (product, key) {
                ga('ecommerce:addProduct', product);
            });
        },

        /!**
         * Gather all products and send it to google
         *!/
        addTransaction: function (googleOrder) {
            ga('ecommerce:addTransaction', googleOrder);
        }
    };

    window.google_e_commerce = google_e_commerce;
}(window));*/
