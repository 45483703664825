var Return = function() {

    var changeProductsByOrder = function() {
        $('#order_id').change(function() {

            var id = $(this).val();
            $("[class^=order-container]").addClass('hide');
            $('.unlock_return').prop('checked', false);
            $('.order-container-'+id).removeClass('hide');
            $('.quantity').prop('disabled', true);
            $('.reason').prop('disabled', true);

            if ($('.order-container-'+id).has('div')) {
                $('#return-form-wrp').removeClass('hidden');
            } else {
                $('#return-form-wrp').addClass('hidden');
            }

            updateDeliveryMethod(true);
        });
    };

    var updateDeliveryMethod = function (changed) {
        changed = changed || false;
        var courier_id = $('#order_id').find(':selected').data('courierid');
        var fvs_required = $('#shipping_method_id').find(':selected').data('fvs-required');

        $('#fvs_number_required').val(fvs_required);
        $('#shipping_method_id').find('option[data-strict="1"]').each(function() {
            if ($(this).val() != courier_id) {
                $(this).attr('disabled', true);
            } else {
                $(this).attr('disabled', false);
            }
        });

        if (changed) {
            $('#shipping_method_id').val(null).trigger('change');
        }

        $('#shipping_method_id').select2('destroy');
        $('#shipping_method_id').select2({
            minimumResultsForSearch: -1
        });
    }

    var changePaymentByDelivery = function() {
        $('#shipping_method_id').change(function() {
            updatePaymentMethod(true);
        });
    }

    var updatePaymentMethod = function(changed) {
        changed = changed || false;
        var payment_strict = $('#shipping_method_id').find(':selected').data('payment-strict');

        if (payment_strict) {
            $('#money_method_id option').each(function() {
                if ($(this).val() != 1) {
                    $(this).attr('disabled', true);
                }
            });
        } else {
            $('#money_method_id option').each(function() {
                $(this).attr('disabled', false);
            });
        }

        if (changed) {
            $('#money_method_id').val(null).trigger('change');
        }

        $('#money_method_id').select2('destroy');
        $('#money_method_id').select2({
            minimumResultsForSearch: -1
        });
    }

    var enableProductOnCheckbox = function () {
        $(document).on('keyup', '.unlock_return', function() {
            var quantityInput = $(this).closest('.return-product-list').find('.quantity');
            var reasonInput = $(this).closest('.return-product-list').find('.reason');
            if(this.checked) {
                quantityInput.prop('disabled', true);
                reasonInput.prop('disabled', true);
            } else {
                quantityInput.prop('disabled', false);
                reasonInput.prop('disabled', false);
            }
        });
    };

    var returnFvsNumberVisibility = function() {
        var fvs_required = $('#shipping_method_id').find(':selected').data('fvs-required');

        if (fvs_required == 1 || fvs_required == 0) {
            $('#fvs_number_required').val(fvs_required);
        }

        $('#shipping_method_id').on('change', function() {
            var fvs_required = $('#shipping_method_id').find(':selected').data('fvs-required');
            $('#fvs_number_required').val(fvs_required);

            if (fvs_required == 1) {
                showFvsNumber();
            } else {
                hideFvsNumber();
            }
        });
    }

    var showFvsNumber = function () {
        var fvsNumberField = $('#fvs-number-field');

        if (fvsNumberField.hasClass('hidden')) {
            fvsNumberField.removeClass('hidden');
            $('#return_fvs').prop('required',true);
        }
    };


    var hideFvsNumber = function () {
        var fvsNumberField = $('#fvs-number-field');

        if (!fvsNumberField.hasClass('hidden')) {
            fvsNumberField.addClass('hidden');
            $('#return_fvs').removeAttr('required');
        }
    };

    var accNumberVisibility = function() {
        $('#money_method_id').on('change', function() {
            if ($(this).val() == 1) {
                showAccNumber();
            } else {
                hideAccNumber();
            }
        })
    };

    var showAccNumber = function () {
        var accNumberField = $('#acc-number-field');

        if (accNumberField.hasClass('hidden')) {
            accNumberField.removeClass('hidden');
            $('#acc_num').prop('required',true);
        }
    };


    var hideAccNumber = function () {
        var accNumberField = $('#acc-number-field');

        if (! accNumberField.hasClass('hidden')) {
            accNumberField.addClass('hidden');
            $('#acc_num').removeAttr('required');
        }
    };

    var submitLockSpin = function() {
        $('#submit-return').on('click', function() {
            $(this).attr('disabled', true);
            $(this).find('.text').addClass('hidden');
            $(this).find('.fa-spin').removeClass('hidden');
            $(this).closest('form').submit();

        });
    };

    return {
        init: function() {
            changeProductsByOrder();
            changePaymentByDelivery();
            enableProductOnCheckbox();
            accNumberVisibility();
            returnFvsNumberVisibility();
            updateDeliveryMethod();
            updatePaymentMethod();
            submitLockSpin();
        }
    }
}();