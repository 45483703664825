/**
 * Minicart component
 * TODO: MayBE -?- Should be splitted into 2 components (MiniCart & ShoppingBag)
 */
(function (window) {
    var MINICART = (function () {
        var hoverTime = 1000;
        var addTime = 5000;
        var hoverTimeout;
        var addToCartEvent = new Event('addToCart');

        function MINICART(shoppingBag, minicart) {
            this.shoppingBag = $(document).find(shoppingBag);
            this.minicart = $(document).find(minicart);
            this.loader = this.minicart.find('.loading');
            this.hover();
            this.clickClose();
            this.addToCart();
        }

        MINICART.prototype = {
            /**
             * Instanly hide minicart
             * When clicking enywhere but not minicart/shoping-bag blocks
             */
            clickClose: function () {
                var self = this;

                $(document).mouseup(function (e)
                {
                    var container = self.shoppingBag.closest('.btn-group');
                    if (! container.is(e.target)
                        && container.has(e.target).length === 0)
                    {
                        clearTimeout(hoverTimeout);
                        self.minicart.hide();
                    }
                });
            },

            /**
             * Hover functionality
             * Show/hide minicart block
             * Hiding block is set to timeout
             */
            hover: function () {
                var self = this;
                self.shoppingBag.closest('.btn-group').mouseenter(function () {
                    var item_count = $(this).find('.counter').text().match(/\d+/);
                    if (item_count[0] != 0) {
                        clearTimeout(hoverTimeout);
                        self.minicart.show();
                    }
                }).mouseleave(function () {
                    hoverTimeout = setTimeout(function () {
                        self.minicart.hide();
                    }, hoverTime);
                });
            },

            /**
             * Add item to cart
             * Updates minicart view on success with newest cart results
             * TODO: MayBE -?- Should be moved to cart js, because of the functionality logic, and only minicart functionality should be left here
             */
            addToCart: function () {
                var self = this;

                $('.product-view form').unbind('submit').on('submit', function (e) {
                    e.preventDefault();

                    // Validate input
                    if ($('select[name^="attribute"]').length > 0 && ! $('select[name^="attribute"]').val()) {
                        $('select[name^="attribute"]').closest('.form-group').addClass('has-error');
                        $('select[name^="attribute"]').closest('.form-group').append('<div class="help-block">' + _translations.mustChoose + '</div>');
                        return false;
                    }

                    // Set variables
                    var product_id = $(this).find('#product_id').val();
                    var product_code = $(this).find('#product_code').val();
                    var product_brand = $(this).find('#product_brand').val();
                    var product_catalogues_title_full = $(this).find('#product_catalogues_title_full').val();
                    var product_sku = $(this).find('#product_sku').val();
                    var quantity = $(this).find('#quantity').val();
                    var product_title = $(this).find('#product_full_title').val();
                    var product_price = $(this).find('#product_price').val();
                    var url = $(this).attr('action');
                    var buy_btn = $(this).find('.buy-btn');

                    var product = {
                        'title': product_title,
                        'id': product_id,
                        'sku': product_sku,
                        'price': product_price,
                        'category': '',
                        'quantity': quantity
                    };

                    var data = {
                        "product_id": product_id,
                        "quantity": quantity
                    };

                    logEvent('add_to_cart', {
                        'currency': 'EUR',
                        'items': [product_sku],
                        'value': product_price,
                        'quantity': quantity
                    });

                    /*passCartCreationToSoundest(data);*/

                    // Remove quantity from product
                    if (! $.isEmptyObject(_variationsFull) && ! $.isEmptyObject(_variationsFull[product_id])) {
                        _variationsFull[product_id].quantity_left--;
                    }

                    if (_is_app_user) {
                        buy_btn.find('.fa-spin').removeClass('hidden');
                        buy_btn.find('.add2cart').addClass('hidden');
                    }
                    self.minicart.show();
                    self.loader.removeClass('hide');

                    clearTimeout(hoverTimeout);

                    $.ajax({
                        url: url,
                        method: "post",
                        data: data,
                        success: function (response) {
                            if (response.success) {
                                $('#cart-item-' + response.cart_item_id).remove();
                                
                                if (typeof enhancedEcomm == "object") {
                                    variant = "";
                                    if ($('#product_variation_select option:selected').length > 0) {
                                        variant = product_sku.replace(product_code+'_', '');
                                    }
                                    enhancedEcomm.pushData('addToCart', [{
                                        'name':product_title,
                                        'id':product_code.toUpperCase(),
                                        'price':product_price,
                                        'category':product_catalogues_title_full,
                                        'brand':product_brand,
                                        'quantity':quantity,
                                        'variant':variant
                                    }]);
                                }

                                google_ga4.add_to_card({'products': [
                                    {
                                        'item_id':product_code.toUpperCase(),
                                        'item_name':product_title,
                                        'price':product_price,
                                        'currency': "EUR",
                                        'item_category':product_catalogues_title_full,
                                        'item_brand':product_brand,
                                        'quantity':quantity,
                                        'item_variant':variant
                                    }
                                ]});
                                
                                // Unhide elements when product added
                                $(document).find('#minicart .hide').removeClass('hide');

                                self.loader.addClass('hide');

                                self.update(response);
                                 $(document).find('#minicart').show();

                                if (_is_app_user) {
                                    buy_btn.find('.fa-spin').addClass('hidden');
                                    buy_btn.find('.fa-check').removeClass('hidden');

                                    setTimeout(function () {
                                        buy_btn.find('.fa-check').addClass('hidden');
                                        buy_btn.find('.add2cart').removeClass('hidden');

                                    }, 1000);
                                } else {
                                    $("html, body").animate({
                                        scrollTop: 0
                                    }, 600);
                                }

                                addToCartEvent.cart_count = response.cart_item_count;
                                document.dispatchEvent(addToCartEvent);

                                hoverTimeout = setTimeout(function () {
                                    $(document).find('#minicart').hide();
                                }, addTime);

                                /*if (typeof datalayer_addToCart == "function") {
                                    datalayer_addToCart(product, 1);
                                }*/
                            } else {
                                if (_is_app_user) {
                                    buy_btn.find('.fa-spin').addClass('hidden');
                                    buy_btn.find('.add2cart').removeClass('hidden');
                                }
                                self.loader.addClass('hide');
                                $(document).find('#minicart').hide();

                                NotificationPopups.toastr().error('Product can\'t be added');
                                $('.input-border').addClass('has-error');
                            }
                        }
                    });

                    return false;
                })
            },

            /**
             * @param {Object} response
             */
            update: function (response) {
                if (response.cart_modal_html) {
                    this.updateFull(response.cart_modal_html);
                }

                if (response.cart_modal_totals_html) {
                    this.updateTotal(response.cart_modal_totals_html);
                }

                if (response.shopping_bag) {
                    this.updateShoppingBag(response.shopping_bag);
                }

                // if (response.product_html) {
                //     this.appendProduct(response.product_html);
                // }
                window.MINICART.create();
            },

            /**
             * Update whole minicart html
             *
             * @param html
             */
            updateFull: function (html) {
                var self = this;
                self.minicart.replaceWith(html);
            },

            /**
             * Update minicart "total" block html
             *
             * @param html
             */
            updateTotal: function (html) {
                var self = this;
                self.minicart.find('.total').html(html);
            },

            /**
             * Update shopping bag all html blocks
             *
             * @param html
             */
            updateShoppingBag: function (html) {
                var self = this;
                self.shoppingBag.replaceWith(html);
            },

            // appendProduct: function (html) {
            //     var self = this;
            //     self.minicart.find('.items').append(html);
            // }
        };

        return MINICART;
    }());

    /**
     * Pass cart params and store in soundest
     */
    function passCartCreationToSoundest(cart_data) {

        /*var data = {
            "cartID": "1119009111",
            "email": "dr.evil@example.com",
            "createdAt": "2017-06-20T15:40:12Z",
            "updatedAt": "2017-06-20T15:45:12Z",
            "emailID": "59099010597ed74f6635fb14",
            "currency": "USD",
            "cartSum": 66666,
            "cartRecoveryUrl": "http://www.example.com/carts/?retoreCart=4557116516"
        };*/

        // $.ajax({
        //     url: '/cart/storeSoundest',
        //     method: "POST",
        //     dataType: 'application/json',
        //     data: cart_data,
        //     success: function (response) {
        //         console.log(response);
        //     },
        //     error : function (error) {
        //         console.log(error);
        //     }
        // });

    }

    MINICART.create = function () {
        window.cart = new MINICART('.shopping-bag', '#minicart');
        return window.cart;
    };

    MINICART.updateFull = function (html) {
        window.cart.updateFull(html);
    };

    MINICART.updateTotal = function (html) {
        window.cart.updateTotal(html);
    };

    MINICART.updateShoppingBag = function (html) {
        window.cart.updateShoppingBag(html);
    };

    window.MINICART = MINICART;
}(window));
window.MINICART.create();