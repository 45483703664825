var Menu = function (options) {

    var self = this;
    self.options = $.extend({

    }, options || {});

    var handleGrey = function () {
        $(".menu li").hover(function () {
            $('.menu li').not(this).addClass('grey');
        }, function () {
            $('.menu li').not(this).removeClass('grey');
        });
    };

    var isTouchDevice = function() {
        return 'ontouchstart' in window || navigator.maxTouchPoints;       // works on IE10/11 and Surface
    };

    /**
     * This function is responsible only for touch screen devices
     * to prevent click on menu which have submenu pop up
     */
    var preventTouchClick = function () {
        $('body').not('.navbar').on('click', function () {
            $('.navbar .dropdown a').removeClass('clicked');
        });

        if (isTouchDevice()) {
            if ($(window).width() > RESPONSIVE_BREAKPOINTS.tablet_p) {
                $('.navbar .dropdown > a').on('click', function (event) {
                    $('.navbar .dropdown a').not($(this)).removeClass('clicked');
                    if (! $(this).hasClass('clicked') && $(this).closest('li').find('ul').length > 0) {
                        $(this).addClass('clicked');
                        event.preventDefault();
                    }
                });
            }
        }
    };

    return {
        init: function (options) {
            self.options = $.extend(self.options, options || {});

            handleGrey();
            preventTouchClick();
        }
    }
}();