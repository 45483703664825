/**
 * Product list filter
 *
 * @author Dominykas Tijūnaitis <dominykas@koralis.lt>
 */
(function (window) {
    var ProductListFilter = (function () {
        function ProductListFilter(options) {
            var main_el = this;
            /**
             * Merge default options with client specified ones
             */
            this.options = $.extend(true, {}, this.defaults, options);

            // this.loadJSTree();

            /**
             * Listeners and preperations
             */
            this.addFilterListeners();
            this.preparePriceInput();
            this.addRemoveListeners();
            this.addPageListeners();
            this.addSortListener();
            this.initSlimscroll();
            this.addDots();

            $( window ).resize(function() {
                main_el.addDots();
            });
        }

        var pager_link_selector = "#product-filter-list .pagination li a";
        if (_is_app_user) {
            pager_link_selector = "#product-filter-list .pagination li a.non-exist";
        }

        ProductListFilter.prototype = {
            /**
             * Options
             */
            options : {},
            /**
             * Default options
             */
            defaults: {

                /**
                 * This is were all of the date and settings and being held
                 */
                data: {
                    page: 1,
                    sort: '-recommended',
                    filter: {}
                },

                /**
                 * The request url
                 */
                url: '',
                search: '',

                skip_price: false,
                //skip_attributes: false,
                page_clicked: false,

                /**
                 * This is used for the history state to check
                 * if the request was made by a filter change or a back/forward button
                 */
                user_click: 0,

                /**
                 * The generated HTTP GET query
                 */
                query: {},

                /**
                 * We store all the AJAX request to be able to cancel them if another request is made
                 * while the previous one is still working
                 */
                xhrPool: [],

                /**
                 * Delay is stored here to forbid request spam
                 */
                timeout: 0,

                /**
                 * Selectors for every element used in this product filter
                 */
                elements: {

                    /**
                     * Element which will be blocked when a request is made
                     */
                    block_element: ".main",

                    /**
                     * Where all the new products data will go
                     */
                    products: "#product-filter-list",//"#product-filter-list .inner",

                    /**
                     * Show/hide more items on the attribute list
                     */
                    //toggle_items: ".product-filter-more-items",

                    /**
                     * Change sort selector
                     */
                    change_sort: ".product-filter-change-sort",

                    /**
                     * Where the sort text will be placed
                     */
                    sort_text: ".product-filter-change-sort-text",

                    /**
                     * Selector for previewing total count
                     */
                    total_count: ".product-filter-total-count",

                    /**
                     * Page change selector
                     */
                    page_change: pager_link_selector,

                    /**
                     * Remove single filter selector
                     */
                    remove_filter: ".product-filter-remove",

                    /**
                     * Remove Price filter selector
                     */
                    remove_price_filters: ".product-filter-remove-price",

                    /**
                     * Remove all filters selector
                     */
                    remove_filters: "#product-filter-remove-attributes",

                    /**
                     * Global selector for all inputs
                     */
                    filter: ".product-filter-filter",

                    /**
                     * Filter selectors
                     */
                    filters: {
                        left: ".product-filter-left-filters",
                        // top: ".product-list-top", //"#product-filter-top-filters",
                        top_selected_attributes: ".product-list-filter-selected"
                    },

                    /**
                     * Input selectors for all the filterable fields
                     */
                    inputs: {

                        /**
                         * Checkbox filter
                         */
                        checkbox: {
                            type: 'simple',
                            selector: ".product-filter-checkbox",
                            filter: ":checked"
                        },

                        /**
                         * Brand filter
                         */
                        brand: {
                            type: 'simple',
                            selector: ".product-filter-brand-checkbox",
                            filter: ":checked"
                        },

                        /**
                         *  Tag filter
                         */
                        tag: {
                            type: 'simple',
                            selector: ".product-filter-tag-checkbox",
                            filter: ":checked"
                        },

                        /**
                         * Catalogue filter
                         */
                        catalogue: {
                            type: 'simple',
                            selector: ".product-filter-catalogue-checkbox",
                            filter: ":checked"
                        },

                        /**
                         * Price filter
                         */
                        price: {
                            type: 'event',
                            event: 'slideStop',
                            selector: ".product-filter-price-filter",
                            from_selector: "#price-slider-from",
                            to_selector: "#price-slider-to",
                            slider: {}
                        }
                    }

                    // /**
                    //  * JsTree selector
                    //  */
                    // tree: {
                    //
                    //     /**
                    //      * Holder
                    //      */
                    //     selector: ".category-tree",
                    //
                    //     /**
                    //      * Selected element
                    //      */
                    //     clicked: ".category-tree .jstree-clicked",
                    // }
                }
            },

            /**
             * Remove single/all/price attributes listener
             */
            addRemoveListeners: function () {
                var self = this;

                $(document).on('click', this.options.elements.remove_filter, function () {
                    var id = $(this).attr('data-id');
                    var type = $(this).attr('data-filter-type');
                    self.options.data.page = 1;

                    if (type === 'catalogues') {
                        self.options.url = self.removeParam(decodeURIComponent(self.decodeEntities(self.options.url)), 'catalogues', id);
                        $(document).find('input[name="catalogues[' + id + ']"]').prop('checked', false).trigger('click');
                    } else if (type === 'brands') {
                        self.options.url = self.removeParam(decodeURIComponent(self.decodeEntities(self.options.url)), 'brands', id);
                        $(document).find('input[name="brands[' + id + ']"]').prop('checked', false).trigger('click');
                    } else if (type === 'tags') {
                        self.options.url = self.removeParam(decodeURIComponent(self.decodeEntities(self.options.url)), 'tags', id);
                        $(document).find('input[name="tags[' + id + ']"]').prop('checked', false).trigger('click');
                    } else if (type === 'attributes') {
                        self.options.url = self.removeParam(decodeURIComponent(self.decodeEntities(self.options.url)), 'attributes', id);
                        $(document).find('input[data-filter-attribute="' + id + '"]').prop('checked', false).trigger('click');
                    }

                    return false;
                }).on('click', this.options.elements.remove_filters, function () {
                    self.options.data.page = 1;
                    self.options.skip_price = true;

                    $(document).find('input[name^="catalogues"]').prop('checked', false);
                    $(document).find('input[name^="brands"]').prop('checked', false);
                    $(document).find('input[name^="tags"]').prop('checked', false);
                    $(document).find('input[name^="attributes"]').prop('checked', false);

                    // Reset url
                    var url = self.decodeEntities(self.options.url).split('?');
                    self.options.url = url[0];

                    self.update();

                    return false;
                }).on('click', this.options.elements.remove_price_filters, function () {
                    self.options.skip_price = true;
                    self.update();
                });
            },


            removeParam: function (sourceURL, key, value) {
                var rtn = sourceURL.split("?")[0],
                    param,
                    params_arr = [],
                    queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
                if (queryString !== "") {
                    params_arr = queryString.split("&");
                    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                        param = params_arr[i].split("=");
                        if (param[0] === key) {
                            params_arr.splice(i, 1);

                            if (typeof value !== 'undefined') {
                                var items = param[1].split(',');

                                $.each(items, function (index, val) {
                                    if (val === value) {
                                        items.splice(index, 1);
                                    }
                                });

                                if (items.length > 0) {
                                    params_arr.push(key + '=' + items.join(','));
                                }
                            }
                        }
                    }
                    rtn = rtn + "?" + params_arr.join("&");
                }
                return rtn;
            },


            /**
             * Add the price slider and listener
             */
            preparePriceInput: function () {
                var self = this;
                self.options.elements.inputs.price.slider = $(self.options.elements.inputs.price.selector).slider({
                    step: 0.01
                });
            },

            /**
             * Pagination listener
             */
            addPageListeners: function () {
                var self = this;

                $(document).on("slide", self.options.elements.inputs.price.slider, function(e) {
                    $('span#price-slider-from').text(e.value[0]);
                    $('span#price-slider-to').text(e.value[1]);
                });

                $(document).on('click', this.options.elements.page_change, function () {
                    self.options.data.page = $(this).attr('data-page');
                    self.options.page_clicked = true;
                    self.update();

                    return false;
                });
            },

            /**
             * Sort listener
             */
            addSortListener: function () {
                var self = this;

                $(document).on('click', this.options.elements.change_sort, function () {
                    var value = $(this).attr('data-sort');
                    var text = $(this).text();

                    $(self.options.elements.sort_text).text(text);
                    self.options.data.page = 1;
                    self.options.data.sort = value;
                    self.update();
                });
            },

            /**
             * Prepare jsTree with settings
             */
            // loadJSTree: function () {
            //     var self = this;
            //
            //     $(self.options.elements.tree.selector).each(function () {
            //         $(this).bind('ready.jstree', function(e, data) {
            //             var element = $(this);
            //             setTimeout(function(){
            //                 element.removeClass('hidden');
            //             }, 100);
            //         }).jstree({
            //             "core": {
            //                 "themes":{
            //                     "icons":false
            //                 },
            //                 "expand_selected_onload": true
            //             },
            //             "plugins" : [
            //                 "wholerow"
            //             ]
            //         });
            //     });
            //
            //     setTimeout(function () {
            //         $(self.options.elements.tree.selector).on("changed.jstree", function (e, data) {
            //              var link = data.node.a_attr.href;//$(self.options.elements.tree.clicked).attr('href');
            //              window.location = link;
            //         });
            //     }, 1000);
            // },

            /**
             *  Expand filters by data in cookies
             */
            expandFilters: function ()
            {
                var self = this;
                var settings = self.getBlockSettings();
                var blocks = $(self.options.elements.filters.left + ' .title');

                blocks.each(function (key, value) {
                    if (
                        typeof (settings[$(value).parent().data("target")]) !== "undefined"
                        && settings[$(value).parent().data("target")].show === true
                    ) {
                        $(value).trigger('click');
                        $(this).find('.fa').removeClass('fa-caret-right').addClass('fa-caret-down');
                    } else if (typeof (settings[$(value).parent().data("target")]) !== "undefined"
                        && settings[$(value).parent().data("target")].show === false) {
                        $(value).trigger('click');
                        $(this).find('.fa').removeClass('fa-caret-down').addClass('fa-caret-right');
                    }
                });
            },

            /**
             * Get filter settings from cookie
             */
            getBlockSettings: function()
            {
                var settings = Cookies.get('filter-settings');

                if (typeof settings === "undefined") {
                    settings = "{}";
                }

                return JSON.parse(settings);
            },

            /**
             * Handle filter block click
             *
             * @param trigger
             */
            handleBlock: function (trigger)
            {
                var self = this;
                var icon = trigger.find('.fa');
                var settings = self.getBlockSettings();

                if (icon.hasClass('fa-caret-right')) {
                    icon.removeClass('fa-caret-right').addClass('fa-caret-down');
                    settings[trigger.parent().data("target")] = {
                        show: true
                    };
                } else {
                    icon.removeClass('fa-caret-down').addClass('fa-caret-right');
                    settings[trigger.parent().data("target")] = {
                        show: false
                    };
                }

                //saves field data to cookies
                Cookies.set('filter-settings', JSON.stringify(settings), { expires: 7 });
            },

            /**
             * Attribute change and attribute hidden items listeners
             */
            addFilterListeners: function () {
                var self = this;
                //self.expandFilters();

                /**
                 * Changes filter icon when filter is toggled
                 */
                $(document).on('click', self.options.elements.filters.left + ' .title', function () {
                    self.handleBlock($(this));
                });

                $.each(this.options.elements.inputs, function (index, type) {
                    var event = 'change';

                    if (type.type == 'event') {
                        event = type.event;
                    }

                    $(document).on(event, type.selector, function () {
                        self.options.data.page = 1;

                        // if (index == 'brand') {
                        //     self.options.skip_attributes = true;
                        // }

                        clearTimeout(self.timeout);
                        if ($(this).is(type.filter)) {
                            $(document).find('input[name="'+$(this).attr('name')+'"]').prop('checked', true);
                        } else {
                            $(document).find('input[name="'+$(this).attr('name')+'"]').prop('checked', false);
                        }

                        if (index == 'price') {
                            var slideValue = $(this).slider('getValue');
                            $(document).find('input[name="'+$(this).attr('name')+'"]').each(function(){
                                $(this).val(slideValue);
                            });

                            $('span#price-slider-from').text(slideValue[0].toFixed(2));
                            $('span#price-slider-to').text(slideValue[1].toFixed(2));

                        } else {
                            self.options.skip_price = true;
                        }

                        self.timeout = setTimeout(function () {
                            self.update();
                        }, 300);
                    });
                });
            },

            /**
             * Abort all active ajax requests
             */
            ajaxAbort: function () {
                var self = this;

                $(self.options.xhrPool).each(function (i, jqXHR) {
                    jqXHR.abort();
                    self.options.xhrPool.splice(i, 1);
                });
            },

            /**
             * Build the HTTP GET query
             */
            buildQuery: function () {
                var self = this;
                var data = self.options.data;
                var parameters = {};

                $.each(data, function (type, value) {

                    if (type == 'filter') {
                        $.each(value, function (attributeId, attributeValue) {
                            if (attributeValue.name == 'price') {
                                parameters['price'] = attributeValue.value;
                            } else if (attributeValue.name.indexOf("brands") >= 0) {
                                if (typeof parameters['brands'] === 'undefined') {
                                    parameters['brands'] = [];
                                }

                                parameters['brands'].push(attributeValue.value);
                            } else if (attributeValue.name.indexOf("tags") >= 0) {
                                if (typeof parameters['tags'] === 'undefined') {
                                    parameters['tags'] = [];
                                }

                                parameters['tags'].push(attributeValue.value);
                            } else if (attributeValue.name.indexOf("catalogues") >= 0) {
                                if (typeof parameters['catalogues'] === 'undefined') {
                                    parameters['catalogues'] = [];
                                }

                                parameters['catalogues'].push(attributeValue.value);
                            } else if (attributeValue.name.indexOf("attributes2") >= 0) {
                                if (typeof parameters['attributes2'] === 'undefined') {
                                    parameters['attributes2'] = [];
                                }

                                parameters['attributes2'].push(attributeValue.value);
                            } else if (attributeValue.name.indexOf("attributes3") >= 0) {
                                if (typeof parameters['attributes3'] === 'undefined') {
                                    parameters['attributes3'] = [];
                                }

                                parameters['attributes3'].push(attributeValue.value);
                            } else if (attributeValue.name.indexOf("attributes4") >= 0) {
                                if (typeof parameters['attributes4'] === 'undefined') {
                                    parameters['attributes4'] = [];
                                }

                                parameters['attributes4'].push(attributeValue.value);
                            } else if (attributeValue.name.indexOf("attributes5") >= 0) {
                                if (typeof parameters['attributes5'] === 'undefined') {
                                    parameters['attributes5'] = [];
                                }

                                parameters['attributes5'].push(attributeValue.value);
                            } else if (attributeValue.name.indexOf("attributes6") >= 0) {
                                if (typeof parameters['attributes6'] === 'undefined') {
                                    parameters['attributes6'] = [];
                                }

                                parameters['attributes6'].push(attributeValue.value);
                            } else if (attributeValue.name.indexOf("attributes7") >= 0) {
                                if (typeof parameters['attributes7'] === 'undefined') {
                                    parameters['attributes7'] = [];
                                }

                                parameters['attributes7'].push(attributeValue.value);
                            } else if (attributeValue.name.indexOf("attributes8") >= 0) {
                                if (typeof parameters['attributes8'] === 'undefined') {
                                    parameters['attributes8'] = [];
                                }

                                parameters['attributes8'].push(attributeValue.value);
                            } else if (attributeValue.name.indexOf("attributes9") >= 0) {
                                if (typeof parameters['attributes9'] === 'undefined') {
                                    parameters['attributes9'] = [];
                                }

                                parameters['attributes9'].push(attributeValue.value);
                            } else if (attributeValue.name.indexOf("attributes10") >= 0) {
                                if (typeof parameters['attributes10'] === 'undefined') {
                                    parameters['attributes10'] = [];
                                }

                                parameters['attributes10'].push(attributeValue.value);
                            } else if (attributeValue.name.indexOf("attributes") >= 0) {
                                if (typeof parameters['attributes'] === 'undefined') {
                                    parameters['attributes'] = [];
                                }

                                parameters['attributes'].push(attributeValue.value);
                            } else {
                                parameters[attributeId] = attributeValue.value;
                            }
                        });
                    } else {
                        parameters[type] = value;
                    }
                });

                if (typeof parameters['brands'] !== 'undefined') {
                    parameters['brands'] = parameters['brands'].join(',');
                }
                if (typeof parameters['tags'] !== 'undefined') {
                    parameters['tags'] = parameters['tags'].join(',');
                }
                if (typeof parameters['catalogues'] !== 'undefined') {
                    parameters['catalogues'] = parameters['catalogues'].join(',');
                }
                if (typeof parameters['attributes'] !== 'undefined') {
                    parameters['attributes'] = parameters['attributes'].join(',');
                }
                for (var i = 2; i <= 10; i++) {
                    if (typeof parameters['attributes' + i] !== 'undefined') {
                        parameters['attributes' + i] = parameters['attributes' + i].join(',');
                    }
                }

                if (self.options.search != '') {
                    parameters['search'] = self.options.search; 
                }

                // Add the missing parts from the url
                var query = self.decodeEntities(self.options.url).split('?');

                if(typeof query[1] !== 'undefined'){
                    query[1].split("&").forEach(function(part) {
                        var item = part.split("=");
                        if(typeof parameters[item[0]] === 'undefined'){
                            parameters[item[0]] = decodeURIComponent(item[1]);
                        }
                    });
                }

                self.query = query[0] + "?" + $.param(parameters);
            },
            /**
             * Decode HTML entities
             * @param encodedString
             * @returns {*}
             */
            decodeEntities: function decodeEntities(encodedString) {
                var textArea = document.createElement('textarea');
                textArea.innerHTML = encodedString;
                return textArea.value;
            },
            /**
             * Collect data from the filters
             */
            collectData: function () {
                var self = this;
                var data = [];
                var form = $('.filter-products-form');

                var seen = {};
                $.each(form.serializeArray(), function(index, field) {
                    if (! seen[field.name]) {
                        seen[field.name] = true;
                        data.push(field);
                    }
                });

                data = $.grep(data, function(e) {
                    if (e.name == 'price') {
                        var slider = $(self.options.elements.inputs.price.selector);
                        var minPrice = slider.data("slider-min");
                        var maxPrice = slider.data("slider-max");

                        if (e.value != minPrice + ',' + maxPrice) {
                            return e;
                        }
                    }
                     else {
                        return e;
                    }
                });

                // remove price if attributes or brands pressed
                if (self.options.skip_price !== false) {
                    data = $.grep(data, function(e) {
                        if (e.name != 'price') {
                            return e;
                        }
                    });

                    self.options.skip_price = false;
                }

                self.options.data.filter = data;
                self.buildQuery();
            },

            /**
             * Make the filter request
             */
            update: function () {
                // AJAX Pagination in product list
                var self = this;
                self.collectData();
                self.ajaxAbort();

                $(self.options.elements.block_element).block({
                    message: null,
                    overlayCSS: {
                        backgroundColor: '#fff',
                        opacity: .7
                    }
                });

                $.ajax({
                    url: self.query,
                    method: "get",
                    beforeSend: function (jqXHR) {
                        self.options.xhrPool.push(jqXHR);
                    },
                    complete: function (jqXHR) {
                        var i = self.options.xhrPool.indexOf(jqXHR);

                        if (i > -1) self.options.xhrPool.splice(i, 1);
                    },
                    error: function (jqXHR, textStatus) {
                        $(self.options.elements.block_element).unblock();

                        if (jqXHR.status !== 0) {
                            NotificationPopups.toastr().error(textStatus)
                        }
                    }
                }).success(function (response) {
                    $(self.options.elements.block_element).unblock();
                    self.render(response);
                    self.options.user_click = 1;
                    if (self.options.page_clicked) {
                        self.scrollToProductsTop();
                        self.options.page_clicked = false;
                    }
                    History.pushState(self.options.data, $("title").text(), self.query);
                    $(window).lazyLoadXT();
                    //self.expandFilters();
                    //self.addDots();
                });
            },

            /**
             * Scroll to products page
             */
            scrollToProductsTop: function () {
                $("html, body").animate({
                    scrollTop: $('#product-filter-list').position().top
                }, 600);
            },

            /**
             * Render and update the view
             *
             * @param response
             */
            render: function (response) {
                var self = this;

                //$(self.options.elements.inputs.price.selector).slider('destroy');

                $(self.options.elements.products).html(response.content);
                $(self.options.elements.total_count).html(response.total);
                //$(self.options.elements.filters.left).html(response.filter);

                // To prevent html replace this disable method is used
                // Reset all checkboxes
                // Set disabled as in the response html
                $('input[type="checkbox"]')
                    .prop('disabled', false)
                    .closest('.checkbox')
                    .removeClass('disabled');

                $.each($(response.filter).find('input:disabled'), function() {
                    $('input[data-filter-'+$(this).data('filter-type')+'="'+$(this).val()+'"]')
                        .prop('disabled', true)
                        .closest('.checkbox')
                        .addClass('disabled');
                });

                $(self.options.elements.filters.top_selected_attributes).replaceWith(response.selected);

                // Not so good check
                $('.catalogue-menu').removeClass('filter');
                if ($.trim($(self.options.elements.filters.top_selected_attributes).text()) != "") {
                    $('.catalogue-menu').addClass('filter');
                }


                //self.preparePriceInput();
                //self.initSlimscroll();
            },

            initSlimscroll: function () {
                $('.slim-filter').slimScroll({
                    height: '160px',
                    railVisible: true,
                    alwaysVisible: true,
                    color: '#000',
                    opacity: 1,
                    size: '3px',
                    railColor: '#f0eeef',
                    railOpacity: 1
                });
            },

            addDots: function()
            {
                if (_is_app_user) {
                    $('.new-product-list .title').css('text-overflow', 'ellipsis');
                } else {
                    $('.new-product-list .title').truncate({
                        width: 'auto',
                        token: '&hellip;',
                        side: 'right',
                        addclass: false,
                        addtitle: true
                    });
                }
            }
        };

        return ProductListFilter;
    }());

    ProductListFilter.create = function (options) {
        return new ProductListFilter(options);
    };

    window.ProductListFilter = ProductListFilter;
}(window));