/**
 * Cart component
 */
(function (window) {
    var ProductView = (function () {
        function ProductView(options) {
            this.options = $.extend(true, {}, this.defaults, options);
            this.initSlick();
            this.initScroll();
            this.imageListeners();
            this.variationSelectionListener();
        }

        ProductView.prototype = {
            defaults: {
                zoomed: false
            },

            isTouchDevice: function() {
                return 'ontouchstart' in window        // works on most browsers
                    || navigator.maxTouchPoints;       // works on IE10/11 and Surface
            },

            hideLoader: function() {
                $('.loader').addClass('hidden');
            },

            showLoader: function() {
                $('.loader').removeClass('hidden');
            },

            initSlick: function() {
                var self = this;

                $(document).ready(function () {
                    $('.slider-for').slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                        fade: true,
                        asNavFor: '.slider-nav',
                        speed: 0,
                        touchMove: false,
                        swipe: false,
                        responsive: [
                            {
                                breakpoint: 1200,
                                settings: {
                                    speed: 300
                                }
                            },
                            {
                                breakpoint: 640,
                                settings: {
                                    arrows: true,
                                    dots: true,
                                    touchMove: true,
                                    swipe: true
                                }
                            }
                        ]
                    });

                    $('.slider-nav').slick({
                        variableHeight: true,
                        slidesToShow: $('.slider-nav div').length,
                        infinite: false,
                        slidesToScroll: 1,
                        asNavFor: '.slider-for',
                        dots: false,
                        focusOnSelect: false,
                        vertical: true,
                        arrows: false,
                        responsive: [
                            {
                                breakpoint: 1200,
                                settings: {
                                    slidesToShow: 4,
                                    infinite: true,
                                    variableWidth: true,
                                    vertical: false
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 4,
                                    infinite: true,
                                    variableWidth: true,
                                    vertical: false
                                }
                            }
                        ]
                    });
                });

                if ($(window).width() < 640) {
                    $('.slider-nav').addClass('hidden');
                }

                // Slidebar image activation
                $('.slider-nav').on('click', '.slick-slide', function (e) {
                    var $currTarget = $(e.currentTarget);
                    var index = $currTarget.data('slick-index');
                    var slickObj = $('.slider-for').slick('getSlick');
                    slickObj.slickGoTo(index);
                });

                $('.slider-for').on('afterChange', function(event, slick, currentSlide){
                    // This step is neeed to make eternity slider work
                    if ($(window).width() < 1200 && $(window).width() >= 600 && ! self.isTouchDevice()) {
                        var slide = currentSlide;
                        if (currentSlide >= 2) {
                            slide = currentSlide - 2;
                        } else {
                            slide = $('.slider-for .slick-slide').length - 2 + currentSlide;
                        }
                        var image = $('.slider-nav .slick-slide').eq(slide).find('img').attr('data-image');
                        image = self.checkImage(image);
                    } else {
                        var image = $(document).find('.slider-nav .slick-slide.slick-current').find('img').attr('data-image');
                    }

                    $("#product-image img").removeClass('enabled');
                    $("#product-image .slick-slide.slick-current img").attr('data-zoom-image', image);
                    self.productZoom();
                });

            },




            initScroll: function() {
                if ($(window).width() >= 1200) {
                    $('#inner-content-div').slimScroll({
                        height: '614px',
                        color: '#fff',
                        railColor: '#fff'
                    });
                }
            },
            imageListeners: function () {
                var self = this;

                $(window).resize(function(){
                    waitForFinalEvent(function(){
                        self.productZoom();
                    }, 500);
                });

                self.productZoom();
            },
            productZoom: function () {
                var self = this;

                $(".zoomContainer").remove();
                $("#product-image img").removeData('elevateZoom');
                $("#product-image img").removeData('zoomImage');
                $("#product-image img").removeClass('enabled');

                if ($(window).width() > 767) {
                    if (! self.isTouchDevice()) {
                        $("#product-image .slick-slide.slick-current img").on('mouseover', function () {
                            if ($('.enabled').length === 0) {
                                if ($(".zoomContainer").length === 0) {
                                    self.showLoader();
                                    $("#product-image .slick-slide.slick-current img").elevateZoom({
                                        scrollZoom: false,
                                        zoomWindowHeight: $('#product-image .slick-slide.slick-current img').height(),
                                        zoomWindowWidth: $('.product-info').width(),
                                        zoomType: "inner",
                                        cursor: "zoom-in",
                                        onZoomedImageLoaded: function() {
                                            self.hideLoader();
                                            $('.zoomContainer').show();
                                            $(this).addClass('enabled');
                                            $('.zoomWindowContainer div').show();
                                        }
                                    });
                                }

                                $('.zoomContainer').show();
                                $(this).addClass('enabled');
                            } else {
                                $(this).removeClass('enabled');
                                $('.zoomContainer').hide();
                            }
                        });
                    } else {
                        $("#product-image .slick-slide.slick-current img").on('touchstart', function () {
                            if ($('.enabled').length === 0) {
                                if ($(".zoomContainer").length === 0) {
                                    self.showLoader();
                                    $("#product-image .slick-slide.slick-current img").elevateZoom({
                                        scrollZoom: false,
                                        zoomWindowHeight: $('#product-image .slick-slide.slick-current img').height(),
                                        zoomWindowWidth: $('.product-info').width(),
                                        zoomType: "inner",
                                        cursor: "zoom-out",
                                        onZoomedImageLoaded: function() {
                                            self.hideLoader();
                                            $('.zoomContainer').show();
                                            $(this).addClass('enabled');
                                        }
                                    });
                                }

                                $('.zoomContainer').show();
                                $(this).addClass('enabled');
                            } else {
                                $(this).removeClass('enabled');
                                $('.zoomContainer').hide();
                            }
                        });
                    }
                } else {

                }
            },
            getVariation: function () {
                var self = this;
                $.each(_variations, function (variationId, attributes) {
                    var count = 0;
                    $.each($(document).find('select[name^="attribute"]'), function () {
                        var val = $(this).select2({
                            minimumResultsForSearch: -1
                        }).val();

                        if (typeof attributes[val] !== 'undefined') {
                            count++;
                        }
                    });

                    if (count == Object.keys(attributes).length) {
                        var variationQuantity = _variationsFull[variationId].quantity_left;
                        $('#product_id').val(variationId);
                        $('#quantity').attr('max', variationQuantity);

                        if (typeof _variationsFull[variationId].viewable_price !== 'undefined') {
                            $(".product-info .price-holder").empty();
                            if (_variationsFull[variationId].viewable_price.old_price !== null && typeof _variationsFull[variationId].viewable_price.old_price !== 'undefined') {
                                $(document.createElement('div')).addClass('price').appendTo($(".product-info .price-holder"));
                                $(document.createElement('span')).attr( 'id', 'variation-price' ).html(_variationsFull[variationId].viewable_price.price).appendTo($(".product-info .price"));

                                if(typeof(_variationsFull[variationId].discount) != 'undefined' &&
                                    typeof(_variationsFull[variationId].discount.discount_double) != 'undefined') {

                                    let el = $(document.createElement('div')).addClass('old-price').addClass('old-price-red').appendTo($(".product-info .price-holder"));
                                    $(document.createElement('span')).addClass('price').attr( 'id', 'previous-price' )
                                        .html(_variationsFull[variationId].discount.discount_double.previous_price_formated)
                                        .appendTo(el);
                                }

                                let oldPriceEl = $(document.createElement('div')).addClass('old-price').appendTo($(".product-info .price-holder"));
                                $(document.createElement('span')).html(_variationsFull[variationId].viewable_price.old_price).appendTo(oldPriceEl);
                            } else {
                                $(document.createElement('div')).addClass('normal-price').appendTo($(".product-info .price-holder"));
                                $(document.createElement('span')).html(_variationsFull[variationId].viewable_price.price).appendTo($(".product-info .normal-price"));
                            }
                        }
                    }
                });
            },
            disableAttributes: function (select) {
                var arr = [];
                var selected = parseInt(select.select2({
                    minimumResultsForSearch: -1
                }).val());
                $.each(_variations2, function(key, value){
                    if ($.inArray(selected, value) !== -1) {
                        arr = $.merge( $.merge( [], arr ), value );
                    }
                });

                // find all selects except active one
                $(document).find('select[name^="attribute"]').not(select).find('option').each(function(){
                    var that = $(this);

                    // disable only who is not in the array
                    if ($.inArray(parseInt(that.val()), arr) === -1) {
                        that.prop('disabled', true);
                    } else {
                        that.prop('disabled', false);
                    }

                    // if selected becomes disabled, switch to first enabled oprion
                    if (that.select2({
                            minimumResultsForSearch: -1
                        }).prop('selected') && that.select2({
                            minimumResultsForSearch: -1
                        }).prop('disabled')) {
                        that.prop('selected', false);
                        that.parent().find('option:not([disabled])').first().prop('selected', true);
                    }
                });

                select.find('option').prop('disabled', false);
            },
            variationSelectionListener : function(){
                var self = this;

                if (jQuery.isEmptyObject(_variations) == false) {
                    $(".product-info .labels").show();
                }

                $(document).find('select[name^=attribute]').select2({
                minimumResultsForSearch: -1
                }).on('select2:select', function(){
                    self.getVariation();
                    $(this).closest('.form-group').removeClass('has-error');
                    $(this).closest('.form-group').find('.help-block').remove();
                });
            },

            checkImage: function(image) {
                var matches = image.match(/.*(\-[0-f]{32})/);
                if (matches != null) {
                    $.ajax({
                        async: false,
                        url: image,
                        statusCode: {
                            500: function() {
                                image = image.replace(matches[1] , '');
                            }
                        }
                    });
                }
                return image;
            }
        };

        return ProductView;
    }());

    ProductView.create = function (options) {
        return new ProductView(options);
    };

    window.ProductView = ProductView;
}(window));


var waitForFinalEvent = (function () {
    var timers = {};
    return function (callback, ms, uniqueId) {
        if (!uniqueId) {
            uniqueId = "Don't call this twice without a uniqueId";
        }
        if (timers[uniqueId]) {
            clearTimeout (timers[uniqueId]);
        }
        timers[uniqueId] = setTimeout(callback, ms);
    };
})();