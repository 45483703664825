window.CART_DATA_CHANGE_DISABLED = false;
var addToCartEvent = new Event('addToCart');
var Cart = function () {

    return {
        addressToUpdate: '',
        cartCheckoutOption: '',

        check: function()
        {
            $.ajax({
                url: _localeSlug + '/cart/check',
                method: "get",
                success: function (response) {
                    if (response.success) {
                        $('#cart_page').removeClass('hidden');
                    } else {
                        window.location = response.redirect;
                    }
                }
            });
        },
        init: function (options) {
            Cart.options = options;

            $('#btnLogin').on('click', function() {
                $(document).find('.loginFormWrap, .socialWrap, .registerOpenWrap, .checkoutWithoutLoginWrap, .registerContainer').slideDown( "slow" );
                $(document).find('.loginOpenWrap, .checkoutWithoutLoginFormWrap, .registerFormWrap').slideUp( "slow" );
            });

            $('.btnRegister').on('click', function() {
                $(document).find('.registerFormWrap, .loginOpenWrap, .registerContainer, .checkoutWithoutLoginWrap').slideDown( "slow" );
                $(document).find('.loginFormWrap, .socialWrap, .simple-login.mobile, .checkoutWithoutLoginFormWrap, .registerOpenWrap').slideUp( "slow" );
            });

            $('#btnCheckoutWithoutLogin').on('click', function(e) {
                $(document).find('.checkoutWithoutLoginFormWrap, .loginOpenWrap').slideDown( "slow" );
                $(document).find('.socialWrap, .loginFormWrap, .socialWrap, .simple-login.mobile, .checkoutWithoutLoginWrap, .registerContainer').slideUp( "slow" );
            });

            $(document).find('input[name="user[password]"]').on('keyup', function () {
               $(this).closest('.form-group').removeClass('has-error');
            });

            function initTriggers() {
                var removingItem = false;

                // deletes cart items
                $(document).find('.delete-btn').unbind('click').on('click', function (e) {
                    e.preventDefault();

                    if (window.CART_DATA_CHANGE_DISABLED) {
                        return false;
                    }

                    if (!removingItem) {
                        removingItem = true;

                        // Show spinner
                        var btnRemoveIcon = $(this).find('i.fa-times');
                        var btnSpinner = $(this).find('i.fa-spinner');

                        btnRemoveIcon.addClass('hidden');
                        btnSpinner.removeClass('hidden');

                        // Get data
                        var cartItemId = $(this).data('cartItemId');
                        var cartItemSku = $(this).data('cartItemSku');
                        var cartItemParentSku = $(this).data('productParentSku');
                        var cartProductId = $(this).data('cartProductId');
                        var productTitle = $(this).data('productTitle');
                        var brandTitle = $(this).data('brandTitle');
                        var productQuantity = $(this).data('productQuantity');
                        var productPrice = $(this).data('productPrice');
                        var productCataloguesFullTitle = $(this).data('cataloguesFullTitle');
                        var token = $(this).data('token');
                        var container = $(this).closest('.cart-item');

                        // Highlight product container
                        if (!container.hasClass('highlighted')) {
                            container.addClass('highlighted');
                        }

                        var dataLayer = {
                            'sku': cartItemSku,
                            'title': productTitle,
                            'qunatity': productQuantity,
                            'price': productPrice
                        };

                        $.ajax({
                            url: _localeSlug + '/cart/' + cartItemId + '/delete',
                            method: "delete",
                            data: {
                                "id": cartItemId,
                                "_token": token
                            },
                            success: function (response) {
                                if (response.success) {
                                    container.remove();
                                    updateCartByResponse(response);
                                    //changeShoppingBagItemsCount(response.cart_item_count);
                                    if ($(document).find('#cart_page .product-list.cart-list').find('.cart-item').length <= 0) {
                                        $(document).find('#cart-with-items').addClass('hidden');
                                        $(document).find('#cart-empty').removeClass('hidden');
                                    }

                                    if (typeof enhancedEcomm == "object") {
                                        variant = "";
                                        product_id = cartItemParentSku;
                                        if (cartItemParentSku) {
                                            variant = cartItemSku.replace(cartItemParentSku+'_', '');
                                        } else {
                                            product_id = cartItemSku;
                                        }
                                        
                                        enhancedEcomm.pushData('removeFromCart', [{
                                            'name':productTitle,
                                            'id':product_id.toUpperCase(),
                                            'price':productPrice,
                                            'category':productCataloguesFullTitle,
                                            'brand':brandTitle,
                                            'quantity':productQuantity,
                                            'variant':variant
                                        }]);

                                        google_ga4.remove_from_cart([
                                            {
                                                item_id: product_id.toUpperCase(),
                                                item_name: productTitle,
                                                currency: "EUR",
                                                index: 0,
                                                item_brand: brandTitle,
                                                item_category: productCataloguesFullTitle,
                                                item_variant: variant,
                                                price: productPrice,
                                                quantity: Math.abs(productQuantity)
                                            }
                                        ]);
                                    }
                                    /*if (typeof datalayer_removeFromCart == "function") {
                                        datalayer_removeFromCart(dataLayer, productQuantity);
                                    }*/

                                } else {
                                    NotificationPopups.toastr().error('Product can\'t be removed');
                                }
                                removingItem = false;
                            }
                        });
                    }

                    return false;
                });

                // change quantity
                $(document).find('.product select[name^="cart_item_"]').unbind('change').change(function () {
                     if (window.CART_DATA_CHANGE_DISABLED) {
                        return false;
                    }

                    var product = $(this).closest('.product');
                    var max = parseInt($(this).attr('max'));

                    if (max && parseInt($(this).val()) > max) {
                        $(this).val(max);
                    }

                    // Show spinner
                    product.find('.quantity .fa-spin').removeClass('hidden');

                    var productID = product.data('productId');
                    var productBrand = product.data('brandTitle');
                    var variationId = product.data('productVariationId');
                    var productCataloguesFullTitle = product.data('cataloguesFullTitle');
                    var originalQunatity = parseInt($(this).data("original-quantity"));
                    var quantityChange = parseInt($(this).val()) - originalQunatity;
                    var token = product.find('[name="_token"]').val();
                    
                    $(this).data("original-quantity", $(this).val());

                    $.ajax({
                        url: _localeSlug + '/cart/update_product/page',
                        method: "post",
                        data: {
                            "product_id": productID,
                            "variation_id": variationId,
                            "quantity": quantityChange,
                            "_token": token
                        },
                        success: function (response) {
                            if (response.success) {
                                updateCartByResponse(response, product);

                                if (typeof enhancedEcomm == "object") {
                                    if (quantityChange > 0) {
                                        ecommType = 'addToCart';
                                    } else if (quantityChange < 0) {
                                        ecommType = 'removeFromCart';
                                    }

                                    enhancedEcomm.pushData(ecommType, [{
                                        'name':response.datalayer.title,
                                        'id':response.datalayer.sku.toUpperCase(),
                                        'price':response.datalayer.price,
                                        'category':productCataloguesFullTitle,
                                        'brand':productBrand,
                                        'quantity':Math.abs(quantityChange),
                                        'variant':response.datalayer.variant
                                    }]);
                                }

                                if (quantityChange > 0) {
                                    google_ga4.add_to_card([
                                        {
                                            item_id: response.datalayer.sku.toUpperCase(),
                                            item_name: response.datalayer.title,
                                            currency: "EUR",
                                            index: 0,
                                            item_brand: response.datalayer.item_brand,
                                            item_category: response.datalayer.item_category,
                                            item_category2: response.datalayer.item_category2,
                                            item_category3: response.datalayer.item_category3,
                                            item_category4: response.datalayer.item_category4,
                                            item_variant: response.datalayer.variant,
                                            price: response.datalayer.price,
                                            quantity: Math.abs(quantityChange)
                                        }
                                    ]);

                                } else if (quantityChange < 0) {
                                    google_ga4.remove_from_cart([
                                        {
                                            item_id: response.datalayer.sku.toUpperCase(),
                                            item_name: response.datalayer.title,
                                            currency: "EUR",
                                            index: 0,
                                            item_brand: response.datalayer.item_brand,
                                            item_category: response.datalayer.item_category,
                                            item_category2: response.datalayer.item_category2,
                                            item_category3: response.datalayer.item_category3,
                                            item_category4: response.datalayer.item_category4,
                                            item_variant: response.datalayer.variant,
                                            price: response.datalayer.price,
                                            quantity: Math.abs(quantityChange)
                                        }
                                    ]);
                                }

                            } else {
                                NotificationPopups.toastr().error('Product can\'t be added');
                            }
                            product.find('.fa-spin').addClass('hidden');
                        }
                    });

                });

                // Activate and deactivate coupon submit button by adding/removing "active" class
                $(document).find('#cart_page input[name="coupon_code"]').unbind('click').on('keyup', function (e) {

                    if (window.CART_DATA_CHANGE_DISABLED) {
                        return false;
                    }

                    var element = $(document).find('#apply_coupon');
                    var activeClass = 'active';

                    if (typeof element != 'undefined') {
                        if ($(this).val() != '') {
                            element.addClass(activeClass);
                        } else {
                            element.removeClass(activeClass);
                        }
                    }
                });

                $(document).on('submit', '#submit_counpon', function(e) {
                    e.preventDefault();
                    $(document).find('#apply_coupon').click();
                });
                // Apply coupon
                $(document).find('#apply_coupon').unbind('click').on("click", function () {

                    if (window.CART_DATA_CHANGE_DISABLED) {
                        return false;
                    }

                    var btn = $(this);
                    var couponInputElement = $(document).find('#cart_page input[name="coupon_code"]');

                    if (couponInputElement.val() == '') {
                        return false;
                    }
                    couponInputElement.attr('readonly', 'readonly');

                    if (typeof btn.attr('has-static-width') == 'undefined') {
                        updateCouponBtnWidth(btn);
                    }

                    var btnApplyText = $(this).find('span.apply');
                    var btnCancelText = $(this).find('span.cancel');
                    var btnSpinner = $(this).find('i.fa-spinner');
                    var btnCheck = $(this).find('i.fa-check');

                    btnApplyText.addClass('hidden');
                    btnCancelText.addClass('hidden');
                    btnSpinner.removeClass('hidden');

                    if (!btn.hasClass('applied')) {

                        var data = {
                            "coupon_code": couponInputElement.val(),
                            "shop_id": $(this).data('shop-id'),
                            "_token": $(document).find('#cart_page input[name="_token"]').val(),
                            "checkout_options": [{
                                'shop_id': $(this).data('shop-id'),
                            }]
                        };

                        $.ajax({
                            url: _localeSlug + '/cart/add_coupon',
                            method: "post",
                            data: data,
                            success: function (response) {
                                var defaultNotification = true;
                                couponInputElement.removeAttr('readonly');

                                if (typeof response.success != 'undefined') {
                                    if (response.success) {
                                        updateCartByResponse(response);
                                        setTimeout(function () {
                                            $(document).find('#cart_summary_box .coupon_discount span')
                                                .fadeOut(300)
                                                .fadeIn(300)
                                                .fadeOut(300)
                                                .fadeIn(300);
                                        }, 100);

                                        btn.addClass('applied');
                                        couponInputElement.attr('readonly', 'readonly');
                                        defaultNotification = false;
                                    } else {
                                        if (response.errors) {
                                            for (var key in response.errors) {
                                                NotificationPopups.toastr().error(response.errors[key]);
                                            }
                                            defaultNotification = false;
                                        }
                                    }
                                }

                                if (defaultNotification) {
                                    NotificationPopups.toastr().error('Coupon can\'t be added');
                                }

                                btnSpinner.addClass('hidden');
                                btnCheck.removeClass('hidden');

                                setTimeout(function () {
                                    btnCheck.addClass('hidden');

                                    if (btn.hasClass('applied')) {
                                        btnApplyText.addClass('hidden');
                                        btnCancelText.removeClass('hidden');
                                    } else {

                                        btnApplyText.removeClass('hidden');
                                        btnCancelText.addClass('hidden');
                                    }
                                    updateCouponBtnWidth(btn);
                                }, 1000);
                            }
                        });
                    } else {

                        var data = {
                            "coupon_code": "remove",
                            "shop_id": $(this).data('shop-id'),
                            "_token": $(document).find('#cart_page input[name="_token"]').val(),
                            "checkout_options": [{
                                'shop_id': $(this).data('shop-id'),
                            }]
                        };

                        $.ajax({
                            url: _localeSlug + '/cart/add_coupon',
                            method: "post",
                            data: data,
                            success: function (response) {
                                var defaultNotification = true;

                                if (typeof response.success != 'undefined') {
                                    if (response.success) {
                                        updateCartByResponse(response);

                                        btn.removeClass('applied');
                                        couponInputElement.removeAttr('readonly');
                                        couponInputElement.val('');
                                        defaultNotification = false;
                                    } else {
                                        if (response.errors) {
                                            for (var key in response.errors) {
                                                NotificationPopups.toastr().error(response.errors[key]);
                                            }
                                            defaultNotification = false;
                                        }
                                    }
                                }

                                if (defaultNotification) {
                                    NotificationPopups.toastr().error('Coupon can\'t be removed');
                                }

                                btnSpinner.addClass('hidden');
                                btnCheck.removeClass('hidden');

                                setTimeout(function () {
                                    btnCheck.addClass('hidden');

                                    if (btn.hasClass('applied')) {
                                        btnApplyText.addClass('hidden');
                                        btnCancelText.removeClass('hidden');
                                    } else {
                                        btnApplyText.removeClass('hidden');
                                        btnCancelText.addClass('hidden');
                                    }
                                    updateCouponBtnWidth(btn);
                                }, 1000);
                            }
                        });
                    }
                });

                $(document).find('#submit-summary-form-mobile, .cart-step-1-summary #cart_summary_box #submit-summary-form').unbind('click').on('click', function() {
                    $(document).find('.cart-step-1').submit();
                });
                // Carts first step
                $(document).find('.cart-step-1').unbind('submit').on('submit', function (e) {
                    requiredElements = $(this).find('.required-select').filter(':visible');
                    requiredElements.closest('.error-append').find('.help-block').remove();
                    var event = e;

                    if (_is_app_user) {
                        event.preventDefault();
                        native.checkout();
                    }

                    requiredElements.each(function (index, value) {
                        element = $(value);
                        if (!element.val()) {
                            event.preventDefault();
                            element.closest('.form-group').addClass('has-error');
                            if (!element.closest('.error-append').find('.help-block').length) {
                                element.closest('.error-append').append('<span class="help-block">' + Cart.options.error_text + '</span>');
                            }
                        } else {
                            element.closest('.form-group').removeClass('has-error');
                        }
                    });
                });

                $(document).find('input.itemRadio[name="shippingMethod"]').unbind('change').on('change', function (e) {
                    e.preventDefault();
                    $(document).find('.form-group').removeClass('has-error');
                    $(document).find('.help-block').remove();

                    if (window.CART_DATA_CHANGE_DISABLED) {
                        return false;
                    }

                    var shippingID = $(this).val();
                    var prefilled = $(this).data('prefilled');

                    // Active & hidden
                    var shippingMethodListItemactive = $('.shippingMethodListItem.active');
                    shippingMethodListItemactive.removeClass('active');
                    var shippingMethodListItemCurrent = $(this).closest('.shippingMethodListItem');
                    shippingMethodListItemCurrent.addClass('active');

                    if(shippingMethodListItemCurrent.data('forceEdit')){
                        $(".info").addClass('hidden');
                        $(".edit").removeClass('hidden');
                    }else{
                        if(prefilled){
                            $(".info").removeClass('hidden');
                            $(".edit").addClass('hidden');
                        }else{
                            $(".info").addClass('hidden');
                            $(".edit").removeClass('hidden');
                        }
                    }

                    $(".shippingMethodFields").addClass('hidden');
                    $(".shippingMethodFields[data-id='" + shippingID + "']").removeClass('hidden');

                    // Check COD
                    if ($(this).data('cod')) {
                        Payment.showCODPayment();
                    } else {
                        Payment.hideCODPayment();
                    }

                    // Actual data update
                    var data = {
                        // '_token': $(document).find('input[name="_token"]').val(),
                        'action': 'changed_shipping_method',
                        'courier_id': $(this).val()
                    };

                    $.ajax({
                        url: _localeSlug + '/cart/pay/address',
                        method: "post",
                        data: data,
                        success: function (response) {
                            var defaultNotification = true;

                            if (typeof response.success != 'undefined') {
                                if (response.success) {
                                    updateCartByResponse(response);
                                    defaultNotification = false;
                                } else {
                                    if (response.errors) {
                                        for (var key in response.errors) {
                                            NotificationPopups.toastr().error(response.errors[key]);
                                        }
                                        defaultNotification = false;
                                    }
                                }
                            }

                            if (defaultNotification) {
                                NotificationPopups.toastr().error('Courier couldn\'t be changed.');
                            }
                        }
                    });
                });

                var processingPay = false;

                $(document).find('#actualPay').unbind('click').on('click', function (e) {
                    e.preventDefault();
                    $(document).find('.form-group').removeClass('has-error');

                    var self = $(this);

                    if (!processingPay) {

                        // Get data
                        var courierId = $(document).find('input[name="shippingMethod"]:checked').val();
                        var cheapestCourierPrice = $(document).find('input[name="cheapest_delivery"]').val();
                        var cheapestCourierId = $(document).find('input[name="cheapest_delivery_id"]').val();
                        var paymentMethodId = $(document).find('input[name="paymentMethod"]:checked').attr('data-id');

                        var terminalId = null;
                        var terminalParentElm = $(document).find('.shippingMethodFields[data-id="' + courierId + '"]');

                        if (terminalParentElm.find('select[name="terminal"]').length) {
                            terminalId = terminalParentElm.find('select[name="terminal"]').val();
                        }

                        // TODO patikrinti vartotojo informacija
                        let guestUser = {};
                        if($('#guest_first_name_id') && $('#guest_user_id')) {
                            guestUser.id = $('#guest_user_id').val();
                            guestUser.first_name = $('#guest_first_name_id').val();
                            guestUser.last_name = $('#guest_last_name_id').val();
                            guestUser.phone_number = $('#guest_phone_id').val();
                        }

                        var data = {
                            '_token': $(document).find('input[name="_token"]').val(),
                            'courier_id': courierId,
                            'payment_method_id': paymentMethodId,
                            'terminal_id': terminalId,
                            'shop_id': $(document).find('input[name="shop_id"]').val(),
                            'cheapest_courier_price': cheapestCourierPrice,
                            'cheapest_courier_id': cheapestCourierId,
                            'address': {},
                            'terms' : $(document).find('.terms_services_option').val(),
                            'newsletter': $(document).find('.newsletter_type').val(),
                            'rules_agree': $('#rules_agree_checkbox').is(':checked') ? $('#rules_agree_checkbox').val() : 0,
                            'privacy_policy': $('#privacy_policy_checkbox').is(':checked') ? $('#privacy_policy_checkbox').val() : 0,
                        };

                        if(guestUser.hasOwnProperty("first_name") && guestUser.hasOwnProperty("last_name")) {
                            data.guest_user_info = guestUser;
                        }

                        if(data.rules_agree && data.privacy_policy) {
                            data.terms = 1;
                        }

                        data.address['first_name'] = $(document).find('input#first_nameGlobal').val();
                        data.address['last_name'] = $(document).find('input#last_nameGlobal').val();
                        data.address['phone'] = $(document).find('input#phoneGlobal').val();
                        data.address['terms'] = data.terms;


                        // Get address data
                        var inputs = terminalParentElm.find('input');
                        var selects = terminalParentElm.find('select');

                        inputs.each(function () {
                            data.address[$(this).attr('name')] = $(this).val();
                        }).attr('readonly', 'readonly');

                        selects.each(function () {
                            data.address[$(this).attr('name')] = $(this).val();
                        });

                        $.ajax({
                            url: _localeSlug + '/cart/get',
                            method: "get",
                            success: function (response) {
                                if(response && typeof(response.products) != 'undefined' ) {
                                    logEvent('add_payment_info', {
                                        'ecommerce': {
                                            'checkout': {
                                                'actionField': {'step': 1, 'option': data.payment_method_id},
                                                'courier': data.courier_id,
                                                'products': response.products
                                            }
                                        }});
                                }
                            }
                        });

                        processingPay = true;
                        disableCartInteraction();
                        self.addClass('active').attr('readonly', 'readonly').addClass('readonly');
                        self.find('.fa-spin').removeClass('hidden');

                        $.ajax({
                            url: _localeSlug + '/cart/create_order',
                            method: "post",
                            data: data,
                            success: function (response) {
                                // console.log(response);
                                var defaultNotification = true;
                                var enableInteraction = false;

                                if (typeof response.success != 'undefined') {
                                    if (response.success) {
                                        if (response.url) {
                                            window.location.href = response.url;
                                        } else if (response.banklink) {
                                            $('#cart_summary_box').append('<form method="post" action="'+ response.banklink.url+'" id="banklink_form_sub">'+ response.banklink.inputs+'</form>');
                                            document.getElementById('banklink_form_sub').submit();
                                        }
                                        defaultNotification = false;
                                    } else {
                                        if (response.errors) {
                                            $(document).find('.help-block').remove();

                                            for (var key in response.errors) {

                                                var inputWithError = $(document).find('input[name="' + key + '"]');
                                                var selectWithError  = $(document).find('select[name="' + key + '"]');

                                                inputWithError.after('<span class="help-block">' + response.errors[key] + '</span>');
                                                selectWithError.parent().append('<span class="help-block">' + response.errors[key] + '</span>');

                                                inputWithError.closest('.form-group').addClass('has-error');
                                                selectWithError.closest('.form-group').addClass('has-error');

                                                if (inputWithError.length == 0 && selectWithError.length == 0) {
                                                    NotificationPopups.toastr().error(response.errors[key]);
                                                }
                                            }

                                            if (response.errors.payment_error) {
                                                var paymentError = $(document).find('.title.payment');
                                                paymentError.after('<span class="help-block">' + response.errors.payment_error + '</span>');
                                                paymentError.parent().addClass('has-error');
                                            }

                                            if (response.errors.courier_error) {
                                                var courierError = $(document).find('.title.courier');
                                                courierError.after('<span class="help-block">' + response.errors.courier_error + '</span>');
                                                courierError.parent().addClass('has-error');
                                            }

                                            if (response.errors.terms) {
                                                var termsError = $(document).find('.form-group.terms_services');
                                                termsError.after('<span class="help-block col-md-12">' + response.errors.terms + '</span>');
                                                termsError.parent().addClass('has-error');
                                            }

                                            defaultNotification = false;
                                            enableInteraction = true;
                                        }
                                    }
                                }
                                processingPay = false;

                                if (response.message) {
                                    NotificationPopups.toastr().error(response.message);
                                } else if (defaultNotification) {
                                    NotificationPopups.toastr().error('Order couldn\'t be created.');
                                    enableInteraction = true;
                                }

                                if (enableInteraction) {
                                    enableCartInteraction();
                                    self.removeClass('active').removeAttr('readonly').removeClass('readonly');
                                    self.find('.fa-spin').addClass('hidden');
                                }
                            }
                        });
                    }
                });

                $(document).find('#openEdit').unbind('click').on('click', function (e) {
                    e.preventDefault();

                    var self = $(this);

                    $('.info').addClass('hidden');
                    $('.edit').removeClass('hidden');

                    $('.shippingMethodListItem.active').data('forceEdit', true);

                    return false;
                });

                function disableCartInteraction() {
                    $('#cart_page').find('input').attr('readonly', 'readonly').addClass('readonly');
                    $('#cart_page').find('select').attr('readonly', 'readonly').addClass('readonly');

                    window.CART_DATA_CHANGE_DISABLED = true;
                }

                function enableCartInteraction() {
                    $('#cart_page').find('input').removeAttr('readonly').removeClass('readonly');
                    $('#cart_page').find('select').removeAttr('readonly', 'readonly').removeClass('readonly');

                    window.CART_DATA_CHANGE_DISABLED = false;
                }

                /**
                 * Disable links on cart items in the summary box
                 */
                $(document).find('#cart_items .product a').unbind('click').on('click', function (e) {
                    e.preventDefault();
                    return false;
                });

                initScroll();
            }

            function updateCouponBtnWidth(btn) {
                btn.width('auto');
                btn.attr('has-static-width', 1)
                    .width(parseInt(btn.width()));
            }

            /**
             * @param {Object} response
             */
            function updateCartByResponse(response, product) {
                addToCartEvent.cart_count = response.cart_item_count;
                document.dispatchEvent(addToCartEvent);

                if (response.cart_checkout_html) {
                    updateCartSummaryHtml(response.cart_checkout_html);
                    initTriggers();
                    $(window).trigger('scroll');
                }
                if (response.product_html && typeof product != 'undefined') {
                    updateCartItemHtml(product, response.product_html);
                    initTriggers();

                    $('select').select2({
                        minimumResultsForSearch: -1
                    });
                }
                if (response.cart_modal_html) {
                    window.MINICART.updateFull(response.cart_modal_html);
                }
                if (response.cart_modal_totals_html) {
                    window.MINICART.updateTotal(response.cart_modal_totals_html);
                }
                if (response.shopping_bag) {
                    window.MINICART.updateShoppingBag(response.shopping_bag);
                }
                window.MINICART.create();
            }

            /**
             * @param {string} html
             */
            function updateCartSummaryHtml(html) {
                $(document).find('#cart_summary_box').replaceWith(html);
                updateTermsAndServiceCheck();
                updateNewsletter();
            }

            function updateTermsAndServiceCheck() {
              var eleme = $('.terms_services_option');
              var val = eleme.attr('value');
              if (val === '0') {
                $("#terms_and_conditions").attr('checked', false);
              } else {
                $("#terms_and_conditions").attr('checked', true);
              }
            }
            
            function updateNewsletter()
            {
              var clicked = $('.clicked-value');
              var clicked_val = clicked.attr('value');

              if (clicked_val === '1') {
                var eleme = $('.newsletter_type');
                var val = eleme.attr('value');
                if (val === '0') {
                  $('#declineBtn').addClass('clicked');
                } else {
                  $('#acceptBtn').addClass('clicked');
                }
              }
            }

            /**
             * @param {object} product
             * @param {string} html
             */
            function updateCartItemHtml(product, html) {
                product.closest('.cart-item').replaceWith(html);
            }

            /**
             * Cart summary container sticky thingy
             */

            function getCartSummaryContainer() {
                return $(document).find('#cart_summary_box');
            }

            function handleSticky() {
                if (typeof getCartSummaryContainer() == 'undefined') {
                    return false;
                }

                if ($(window).width() >= RESPONSIVE_BREAKPOINTS.tablet_l) {
                    if ($(this).scrollTop() > $('#menu').height() + 30 + 5) {
                        addSticky();
                    } else {
                        removeSticky();
                    }
                } else {
                    removeSticky();
                }
            }

            function removeSticky() {
                getCartSummaryContainer().removeClass("sticky").width('initial');
            }

            function addSticky() {
                var cont = getCartSummaryContainer();
                cont.width(cont.width()).addClass("sticky");
            }

            /**
             * Add scroll if needed for summary box cart items list
             */
            function initScroll() {
                var products = $(document).find('.cont .product');

                if (
                    $(window).width() >= RESPONSIVE_BREAKPOINTS.tablet_l
                    && products.length > 2
                ) {
                    scrollHeight = '320px';
                    if ($(window).height() < 700) {
                        scrollHeight = '260px';
                    }
                    
                    $(document).find('.cont').slimscroll({
                        height: scrollHeight,
                        // DO NOT DELETE This is a new style
                        distance: '-5px',
                        alwaysVisible: true,
                        size: '4px',
                        color: '#000',
                        opacity: 1,
                        railVisible: true,
                        railColor: '#efedee',
                        railOpacity: 1,
                        disableFadeOut: false
                    }).removeClass('hidden');
                } else {
                    if (typeof $(document).find('.cont') != 'undefined') {
                        $(document).find('.cont').slimScroll({
                            destroy: true
                        }).height('initial').removeClass('hidden');
                    }
                }
            }

            function initLoginCart() {
                var width = window.innerWidth ;

                // if (width <= 991) {
                //     $('.simple-login').addClass('mobile');
                // } else {
                //     $('.simple-login').removeClass('mobile');
                // }
            }

            $(window).scroll(function () {
                handleSticky();
            });

            $(window).resize(function () {
                handleSticky();
                initScroll();
                initLoginCart();
            });

            initScroll();
            initTriggers();
            initLoginCart();
        }
    }
}();

$(document).find('a[data-toggle="popover"]').popover();