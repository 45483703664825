/**
 * Header specific JS
 */
$(window).ready(function () {
    $('.burger, .navbar-toggle-global').click(function () {
        if ($(".burger").hasClass('active')) {
            $("#menu-mobile-offcanvas, #menu-mobile-offcanvas-filter").hide();
            $("main, footer").removeClass('hidden');
        } else {
            var element = $(this).attr('data-target');
            $(element).show();
            $(element).css({
                left:0
            });
            $("main, footer").addClass('hidden');
        }

        $(".burger").toggleClass('active');
    });
});