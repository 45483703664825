/**
 * Lookbook component
 */
(function (window) {
    var lookbook = (function () {
        function lookbook() {
            this.breakpoint = 768;
            this.cls = 'mobile-lookbook';

            $('.slider-for').on('beforeChange', function(event, slick, currentSlide, nextSlide){
                $(window).trigger('scroll');
                $(slick.$slides[nextSlide]).find(".block").each(function(index,item){
                    $(item).show();
                });
            });

            $('.the-lookbook .slider-for li.col-xs-12').each(function(index,item){
                if (index == 0) {
                    $($(item).find( ".block" )).each(function(index,item){
                        $(item).show();
                    });
                }
            });


            this.constructDesktop();
            this.fixOverflow();
        }

        lookbook.prototype = {

            fixOverflow: function(element) {
                $('.circle').hover(function() {

                    var element = $(this).find('.hover');
                    var parent = element.parent();
                    var parentPosition = parent.position();
                    var blockWidth = parent.closest('.block').width();
                    var hoverButtom = 32;

                    // check if hover block is to high
                    if (parentPosition.top < element.height() + hoverButtom) {
                        element.css("bottom", parentPosition.top - element.height());
                    }
                    // check if hover block is to left
                    if (parentPosition.left < (element.width() / 2)) {
                        element.css("left", 0);
                    }

                    if (blockWidth < (parentPosition.left + element.width()/2)) {
                        element.css("left", - element.width() - 27/2 + (blockWidth - parentPosition.left - 27));
                    }
                })
            },



            constructDesktop: function () {
                var self = this;

                var centerMode = true;
                var slidesToShow = 3;

                if ($('.slider-for > li').length <= 1) {
                     centerMode = false;
                     slidesToShow = 1;
                }

                $('.slider-for').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: true,
                    fade: false,
                    asNavFor: '.slider-nav',
                    responsive: [
                        {
                            breakpoint: 1230,
                            settings: {
                                arrows: false
                            }
                        }
                    ]
                });

                $('.slider-nav').slick({
                    slidesToShow: slidesToShow,
                    slidesToScroll: 1,
                    asNavFor: '.slider-for',
                    centerMode: centerMode,
                    infinite: true,
                    focusOnSelect: true,
                    centerPadding: false,
                    arrows: false,
                    responsive: [
                        {
                            breakpoint: 480,
                            settings: {
                                arrows: true,
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }
        };

        return lookbook;
    }());

    lookbook.create = function () {
        return new lookbook();
    };

    window.lookbook = lookbook;
}(window));