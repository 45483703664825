var Payment = function (options) {

    var self = this;
    self.options = $.extend({
        defaultPayment: null,
        paymentList: '.paymentMethodList',
        bankList: '.paymentBankList',
        codPaymentId: '2-cash'
    }, options || {});

    /**
     * Set payment
     *
     * @param item
     */
    var setPaymentMethod = function (item) {
        item.addClass('active');
        item.find('.itemContent').removeClass('hidden');
        item.find('.itemRadio').prop('checked', true);
    };

    /**
     * Unset all payments
     */
    var unsetPaymentMethods = function () {
        $(document).find(self.options.paymentList + ' .listItem').removeClass('active');
        $(document).find(self.options.paymentList + ' .itemContent').addClass('hidden');
        $(document).find(self.options.paymentList + ' .itemRadio').prop('checked', false);
    };

    /**
     * Hide COD payment
     */
    var hideCODPayment = function () {
        unsetPaymentMethods();
        self.codItem.closest('.listItem').addClass('hidden');
        setFirst();
    };

    /**
     * Show COD payment
     */
    var showCODPayment = function () {
        self.codItem.closest('.listItem').removeClass('hidden');
    };

    /**
     * Set bank payment
     *
     * @param item
     */
    var setBankPayment = function (item) {
        $(document).find(self.options.bankList + ' .bank.active').removeClass('active');
        item.addClass('active');
        item.closest('.listItem').find('input[name="paymentMethod"]').attr('data-id', item.attr('data-id'));
        if(item.attr('data-id').indexOf('swed_spp') != -1) {
            $('#company-details').show();
        } else {
            $('#company-details').hide();
        }
    };

    /**
     * Set default payment
     */
    var setDefaultPayment = function () {
        // Don't do anything if default payment method is not given
        if (self.options.defaultPayment == null || self.options.defaultPayment == '') {
            return;
        }

        var item = $(document).find(self.options.paymentList + ' input[name="paymentMethod"][data-id="' + self.options.defaultPayment + '"]').closest('.listItem');

        unsetPaymentMethods();
        setPaymentMethod(item);

        var bankItem = $(document).find(self.options.bankList + ' .bank[data-id="' + self.options.defaultPayment + '"]');
        if (bankItem.length > 0) {
            setBankPayment(bankItem);
        }
    };

    /**
     * Set first visible item
     */
    var setFirst = function () {
        var item =  $(document).find(self.options.paymentList + ' .listItem:not(.hidden):first');
        setPaymentMethod(item);
    };

    return {
        init: function (options) {
            self.options = $.extend(self.options, options || {});
            self.codItem = $(document).find(self.options.paymentList + ' input[data-id="' + self.options.codPaymentId + '"]');

            // Set default payment
            setDefaultPayment();

            // Payment item clicked
            $(document).find(self.options.paymentList + ' .listItem').on('click', function() {
                if (window.CART_DATA_CHANGE_DISABLED) {
                    return false;
                }

                unsetPaymentMethods();
                setPaymentMethod($(this));
            });

            // Bank item clicked
            $(document).find(self.options.bankList + ' .bank').on('click', function () {
                setBankPayment($(this));
            });
        },
        setPaymentMethod: function (item) {
            setPaymentMethod(item);
        },
        unsetPaymentMethods: function () {
            unsetPaymentMethods();
        },
        hideCODPayment: function () {
            hideCODPayment();
        },
        showCODPayment: function () {
            showCODPayment();
        }
    }
}();