/**
 * Function responsible for notifications
 * Required plugins:
 *      bootstrap-toastr (global/plugins/bootstrap-toastr/toastr.min.js)
 *      jquery-blockui (global/plugins/jquery.blockui.min.js')
 *
 * @author    Julius Beniušis <julius.beniusis@koralis.lt>
 * @author    Anton Šumilov <anton@koralis.lt>
 */

var NotificationPopups = function () {
    var _this = this;
    _this.alertType = 'alert';

    var POSSIBLE_NOTIFICATION_TYPES = ['success', 'info', 'warning', 'error'];
    var ALERT_TYPES_MAP = {'success': 'success', 'info':'info', 'warning': 'warning', 'error': 'danger'};
    var TOASTR_OPTIONS_MAP = {'duration': 'timeOut', 'close':'closeButton'};
    var ALERT_OPTIONS_MAP = {'duration': 'closeInSeconds'};
    var DEFAUlT_TOASTR_OPTIONS =  {
        "closeButton": true,
        "debug": false,
        "positionClass": "toast-top-right",
        "onclick": null,
        "showDuration": "1000",
        "hideDuration": "1000",
        "extendedTimeOut": "0",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut",
        "timeOut": 0
    };
    var DEFAULT_ALERT_OPTIONS = {

    };

    /**
     * Creates and shows notification
     *
     * @param type
     * @param title
     * @param text
     * @param options
     */
    var message = function (type, title, text, options) {
        if (_this.alertType == 'toastr') {
            if (typeof options == 'undefined') {
                options = [];
            }

            toastr.options = DEFAUlT_TOASTR_OPTIONS;

            if (typeof type == 'undefined'
                || POSSIBLE_NOTIFICATION_TYPES.indexOf(type) < 0){
                type = 'info';
            }

            $.each(options, function(index, value) {
                if (typeof TOASTR_OPTIONS_MAP[index] != 'undefined') {
                    toastr.options[TOASTR_OPTIONS_MAP[index]] = value;
                } else {
                    toastr.options[index] = value
                }
            });

            $(document).ready(function() {
                toastr[type](text, title);
            });
        } else {
            var obj = {};
            obj.options = DEFAULT_ALERT_OPTIONS;
            obj.options.type = ALERT_TYPES_MAP[type];
            obj.options.message = text;

            $.each(options, function(index, value) {
                if (typeof ALERT_OPTIONS_MAP[index] != 'undefined') {
                    obj.options[ALERT_OPTIONS_MAP[index]] = value;
                } else {
                    obj.options[index] = value
                }
            });
            if (typeof obj.options.closeInSeconds != 'undefined') {
                obj.options.closeInSeconds = obj.options.closeInSeconds / 1000;
            }
            App.alert(obj.options);
        }
    };

    /**
     * On click removes any alerts found
     */
    $('.remove-alerts').click(function() {
        $('.alert').addClass('hide');
    });

    /**
     * Module return on call
     */
    return {
        /**
         * Main function to initiate the module
         */
        message: function (type, title, text, options) {
            return message(type, title, text, options);
        },
        success: function (title, text, options) {
            return message('success', title, text, options);
        },
        warning: function (title, text, options) {
            return message('warning', title, text, options);
        },
        error: function (title, text, options) {
            return message('error', title, text, options);
        },
        info: function (title, text, options) {
            return message('info', title, text, options);
        },
        toastr: function () {
            _this.alertType = 'toastr';
            return this;
        },
        get: function () {
            return getNotificationFromSession();
        }
    };
}();
