(function (window) {
    var insideLookbook = (function () {
        function insideLookbook() {
            this.breakpoint = 480;
            this.cls = 'mobile-lookbook';

            // If mobile
            if ($(window).width() < this.breakpoint) {
                this.constructMobile();
            } else {
                this.constructDesktop();
            }

            this.add_listener();
        }

        insideLookbook.prototype = {
            add_listener: function () {
                var self = this;

                $(window).resize(function () {

                    var width = $(window).width();

                    // If mobile
                    if (width < self.breakpoint) {
                        if (! $('body').hasClass(self.cls)) {
                            self.destroyDesktop();
                            self.constructMobile();
                        }
                    } else {
                        if ( $('body').hasClass(self.cls)) {
                            self.destroyMobile();
                            self.constructDesktop();
                        }
                    }
                });
            },

            constructMobile: function () {
                var self = this;
                $('body').addClass(self.cls);

                $('.the_lookbook  .lookbook').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    fade: false,
                });

            },
            constructDesktop: function () {
                var self = this;
                $('body').removeClass(self.cls);

            },

            destroyMobile: function () {
                var self = this;
                if ($('.the-lookbook').length != 0) {
                    // class if homepage page
                    $('.the-lookbook .col-xs-12:not(.slick-cloned) .the_lookbook .lookbook').slick('destroy');
                } else {
                    // class if lookbook page
                    $('.the_lookbook .lookbook').slick('destroy');
                }
            },
            destroyDesktop: function () {
                var self = this;

            }
        };

        return insideLookbook;
    }());

    insideLookbook.create = function () {
        return new insideLookbook();
    };

    window.insideLookbook = insideLookbook;
}(window));