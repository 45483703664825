var Cookie = function() {
    return {
        init: function () {
            $('#modal-cookie-btn').on('click', function () {
              $("#notification-button").click();
            });

            $("#decline-btn").on('click', function() {
              $('#cookieModal').modal('show');
            });

            $("#notification-button").on('click', function() {
              var thirdparty = $('.thirdparty').attr('value');
              var functional = $('.functional').attr('value');

              $.ajax({
                    type: "post",
                    url: _localeSlug + '/cookie_agree',
                    data: {
                        cookie_law: true,
                        functional: functional,
                        thirdparty: thirdparty
                    },
                    dataType: 'json'
                });
                if(parseInt(thirdparty) === 1) {
                    if (typeof gtag === 'undefined') {
                        window.dataLayer = window.dataLayer || [];

                        function gtag() {
                            dataLayer.push(arguments);
                        }
                    }
                    gtag('consent', 'update', {
                        'ad_storage': 'granted',
                        'analytics_storage': 'granted'
                    });
                }

                $("#cookie-notification").slideDown("fast", function() {
                    $(this).remove();
                });
                $('#cookieModal').modal('hide');
            });

            $("#notification-button-close").on('click', function() {
                $("#cookie-notification").slideDown("fast", function() {
                    $(this).remove();
                });
            })
        }
    }
}();