var Shipping = function () {

    var removeError = function () {
        $(document).find('.buyer-info input').on('keypress', function () {
            $(this).closest('.form-group').removeClass('has-error');
            $(this).closest('.form-group').find('.help-block').remove();
        });

        $(document).find('.buyer-info select').on('change', function () {
            $(this).closest('.form-group').removeClass('has-error');
            $(this).closest('.form-group').find('.help-block').remove();
        });

        $(document).find('.buyer-info select2').on('change', function () {
            $(this).closest('.form-group').removeClass('has-error');
            $(this).closest('.form-group').find('.help-block').remove();
        });
    };

    return {
        init: function () {
            removeError();
        },
        removeError: function () {
            removeError();
        }
    }
}();